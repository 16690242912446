import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Global variables
let resizeObserver = null;
let mutationObserver = null;
let resizeHandler = null;
let loadHandler = null;
let domContentLoadedHandler = null;
let isRefreshing = false; // Flag to prevent re-entrant calls
let needsRefresh = false; // Flag to indicate a refresh is scheduled

// Function to check if scrolling is happening
function isScrolling() {
  return document.documentElement.classList.contains("lenis-scrolling");
}

// Function to perform the refresh when appropriate
function attemptRefresh(reason) {
  if (isRefreshing) return; // Prevent re-entrant calls
  isRefreshing = true;

  if (typeof ScrollTrigger !== "undefined") {
    if (!isScrolling()) {
      // Perform the refresh
      ScrollTrigger.refresh();
      needsRefresh = false; // Reset the flag
      isRefreshing = false;
    } else {
      // Wait for scrolling to stop
      const observer = new MutationObserver((mutationsList, observer) => {
        if (!isScrolling()) {
          observer.disconnect();
          ScrollTrigger.refresh();
          needsRefresh = false; // Reset the flag
          isRefreshing = false;
        }
      });

      observer.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ["class"],
      });
    }
  } else {
    console.warn("ScrollTrigger is not available");
    isRefreshing = false;
  }
}

// Function to schedule a refresh
function scheduleRefresh(reason) {
  needsRefresh = true;
  attemptRefresh(reason);
  // console.log(reason);
}

// callAfterResize function
function callAfterResize(func, delay) {
  let dc = gsap.delayedCall(delay || 0.2, func).pause(),
    handler = () => dc.restart(true);
  window.addEventListener("resize", handler);
  return handler; // Return the handler for removal later
}

// ResizeObserver
function setupResizeObserver() {
  if (resizeObserver) {
    resizeObserver.disconnect();
  }

  if ("ResizeObserver" in window) {
    let dc = gsap
      .delayedCall(0.2, () => scheduleRefresh("ResizeObserver"))
      .pause();
    resizeObserver = new ResizeObserver((entries) => {
      dc.restart(true); // Restart the delayed call
    });
    resizeObserver.observe(document.body);
  } else {
    if (resizeHandler) {
      window.removeEventListener("resize", resizeHandler);
    }
    resizeHandler = callAfterResize(
      () => scheduleRefresh("Window resize"),
      0.2
    );
  }
}

// MutationObserver setup (optional, can be uncommented if needed)
function setupMutationObserver() {
  if (mutationObserver) {
    mutationObserver.disconnect();
  }

  let mutationTimeout;
  mutationObserver = new MutationObserver((mutations) => {
    let shouldRefresh = false;
    for (let mutation of mutations) {
      if (
        mutation.type === "childList" ||
        (mutation.type === "attributes" &&
          ["style", "class"].includes(mutation.attributeName))
      ) {
        shouldRefresh = true;
        break;
      }
    }
    if (shouldRefresh) {
      // Debounce the refresh
      clearTimeout(mutationTimeout);
      mutationTimeout = setTimeout(() => {
        scheduleRefresh("DOM mutation");
      }, 100); // Adjust delay as necessary
    }
  });

  mutationObserver.observe(document.body, {
    childList: true,
    subtree: true,
    attributes: true,
    attributeFilter: ["style", "class"],
  });
}

// Cleanup function
function cleanupScrollTriggerRefresh() {
  if (resizeObserver) {
    resizeObserver.disconnect();
    resizeObserver = null;
  }
  if (mutationObserver) {
    mutationObserver.disconnect();
    mutationObserver = null;
  }
  if (resizeHandler) {
    window.removeEventListener("resize", resizeHandler);
    resizeHandler = null;
  }
  if (loadHandler) {
    window.removeEventListener("load", loadHandler);
    loadHandler = null;
  }
  if (domContentLoadedHandler) {
    document.removeEventListener("DOMContentLoaded", domContentLoadedHandler);
    domContentLoadedHandler = null;
  }
}

// Setup function
function setupScrollTriggerRefresh() {
  // Clean up existing observers and listeners
  cleanupScrollTriggerRefresh();

  setupResizeObserver();
  // Uncomment if you want to use MutationObserver
  // setupMutationObserver();

  // Listen for media load events
  loadHandler = () => {
    scheduleRefresh("Window load");
    const mediaElements = document.querySelectorAll("img, video");
    mediaElements.forEach((el) => {
      if (!el.complete) {
        el.addEventListener("load", () => scheduleRefresh("Media loaded"));
      }
    });
  };
  window.addEventListener("load", loadHandler);

  domContentLoadedHandler = () => scheduleRefresh("DOMContentLoaded");
  document.addEventListener("DOMContentLoaded", domContentLoadedHandler);

  // Initial call
  scheduleRefresh("Initial setup");
}

// Export the setup and cleanup functions
export { setupScrollTriggerRefresh, cleanupScrollTriggerRefresh };
