import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let marqueeInstance;
let newsDockingInstance = null;
let scrollTriggerInstance = null;

class InfiniteMarquee {
  constructor(options = {}) {
    this.track = document.querySelector('[news-marquee="track"]');
    this.items = Array.from(this.track.children);
    this.baseSpeed = options.baseSpeed || 1;
    this.currentSpeed = this.baseSpeed;
    this.targetSpeed = this.baseSpeed;
    this.position = 0;
    this.isDragging = false;
    this.startX = 0;
    this.currentX = 0;
    this.lastX = 0;
    this.speedChangeRate = 0.05;
    this.inertia = { x: 0, damping: 0.95 };
    this.lastTimestamp = 0;

    this.setupTrack();
    this.setupEventListeners();
    this.animate();
  }

  setupTrack() {
    this.track.style.display = "flex";
    this.track.style.position = "absolute";
    this.track.style.left = "0px";
    this.track.style.userSelect = "none";
    this.track.style.cursor = "grab";

    // Calculate total width of original items
    this.totalWidth = this.items.reduce(
      (total, item) => total + item.offsetWidth,
      0
    );

    // Clone items to ensure smooth looping
    const clonedItems = this.items.map((item) => item.cloneNode(true));
    clonedItems.forEach((clone) => this.track.appendChild(clone));

    // Set the track width to accommodate both original and cloned items
    this.track.style.width = `${this.totalWidth * 2}px`;

    // Ensure position starts at 0
    this.position = 0;
    this.updateTrackPosition();
  }

  setupEventListeners() {
    this.track.addEventListener("mouseenter", () => this.setTargetSpeed(0.1));
    this.track.addEventListener("mouseleave", () => {
      if (!this.isDragging) this.setTargetSpeed(this.baseSpeed);
    });
    this.track.addEventListener("mousedown", this.startDragging.bind(this));
    document.addEventListener("mousemove", this.drag.bind(this));
    document.addEventListener("mouseup", this.stopDragging.bind(this));
    document.addEventListener("mouseleave", this.stopDragging.bind(this));

    // Prevent default drag behavior on all child elements
    this.track.querySelectorAll("*").forEach((element) => {
      element.addEventListener("dragstart", (e) => e.preventDefault());
    });
  }

  startDragging(e) {
    this.isDragging = true;
    this.startX = e.clientX;
    this.lastX = this.startX;
    this.currentSpeed = 0;
    this.track.style.cursor = "grabbing";
    this.lastTimestamp = performance.now();
  }

  drag(e) {
    if (!this.isDragging) return;
    e.preventDefault();
    this.currentX = e.clientX;
    const diff = this.currentX - this.lastX;
    this.position -= diff;
    this.lastX = this.currentX;
    this.updateTrackPosition();

    // Calculate inertia
    const currentTimestamp = performance.now();
    const deltaTime = currentTimestamp - this.lastTimestamp;
    this.inertia.x = (diff / deltaTime) * 16; // Normalize to ~60fps
    this.lastTimestamp = currentTimestamp;
  }

  stopDragging() {
    if (!this.isDragging) return;
    this.isDragging = false;
    this.track.style.cursor = "grab";
  }

  setTargetSpeed(speed) {
    this.targetSpeed = speed;
  }

  updateSpeed() {
    if (!this.isDragging) {
      if (Math.abs(this.inertia.x) > 0.1) {
        this.position -= this.inertia.x;
        this.inertia.x *= this.inertia.damping;
      } else {
        this.inertia.x = 0;
        if (this.currentSpeed !== this.targetSpeed) {
          this.currentSpeed +=
            (this.targetSpeed - this.currentSpeed) * this.speedChangeRate;
          if (Math.abs(this.currentSpeed - this.targetSpeed) < 0.001) {
            this.currentSpeed = this.targetSpeed;
          }
        }
      }
    }
  }

  updateTrackPosition() {
    this.track.style.transform = `translateX(${-this.position}px)`;

    if (this.position >= this.totalWidth) {
      this.position -= this.totalWidth;
      this.track.style.transform = `translateX(${-this.position}px)`;
    } else if (this.position < 0) {
      this.position += this.totalWidth;
      this.track.style.transform = `translateX(${-this.position}px)`;
    }
  }

  animate() {
    this.updateSpeed();
    if (!this.isDragging && this.inertia.x === 0) {
      this.position += this.currentSpeed;
    }
    this.updateTrackPosition();
    requestAnimationFrame(this.animate.bind(this));
  }

  pause() {
    this.setTargetSpeed(0);
  }

  play() {
    this.setTargetSpeed(this.baseSpeed);
  }

  setSpeed(speed) {
    this.baseSpeed = speed;
    if (!this.isDragging) {
      this.setTargetSpeed(speed);
    }
  }
}

function createMarquee(options = {}) {
  marqueeInstance = new InfiniteMarquee(options);

  // Define the global functions to control the marquee
  window.pauseMarquee = () => marqueeInstance.pause();
  window.playMarquee = () => marqueeInstance.play();
  window.setMarqueeSpeed = (speed) => marqueeInstance.setSpeed(speed);

  return marqueeInstance;
}

function initNewsDocking() {
  const marquee = document.querySelector('[news-marquee="wrap"]');
  const track = marquee.querySelector('[news-marquee="track"]');
  const button = marquee.querySelector(".news__btn--w");
  const items = Array.from(marquee.querySelectorAll(".news__pill--w"));
  const toggle = document.querySelector("[data-news-toggle]");

  const durationReveal = 1;
  const durationHide = 1;
  const ease = "cubic-bezier(.38,.005,.215,1)";
  const staggerReveal = 0.05;
  const staggerHide = 0.03;
  var extraOffset = 500; // Additional offset in pixels, adjust as needed

  let isDocked = false;

  // Calculate the maximum width of all items
  const maxItemWidth = Math.max(...items.map((item) => item.offsetWidth));

  function setInitialState() {
    const offScreenX = -window.innerWidth - maxItemWidth - extraOffset;

    gsap.set(marquee, { pointerEvents: "none" });
    gsap.set(marquee, { display: "none" });
    gsap.set(items, { x: offScreenX });
    gsap.set(button, { x: "-14em" });

    isDocked = true;
  }

  function hideItems() {
    if (isDocked) return;
    window.pauseMarquee();
    gsap.set(marquee, { pointerEvents: "none" });

    // Calculate the distance to move items off-screen
    const offScreenX = -window.innerWidth - maxItemWidth - extraOffset;

    gsap.to(items, {
      x: offScreenX,
      duration: durationHide,
      ease: ease,
      stagger: staggerHide,
      overwrite: true,
      onComplete: () => {
        isDocked = true;
        gsap.set(marquee, { display: "none" });
      },
    });

    gsap.to(button, {
      delay: 0,
      x: "-14em",
      duration: durationHide,
      ease: "power3.in",
      overwrite: true,
    });
  }

  function revealItems() {
    if (!isDocked) return;

    gsap.set(marquee, { display: "flex" });
    gsap.set(marquee, { pointerEvents: "auto" });

    gsap.to(button, {
      x: 0,
      duration: durationReveal,
      ease: ease,
      overwrite: true,
    });

    gsap.to(items, {
      x: 0,
      duration: durationReveal,
      ease: ease,
      stagger: staggerReveal,
      overwrite: true,
      onComplete: () => {
        window.playMarquee();
        isDocked = false;
      },
    });
  }

  function toggleDock() {
    isDocked ? revealItems() : hideItems();
  }

  function createScrollTrigger() {
    const toggle = document.querySelector("[data-news-toggle]");

    if (scrollTriggerInstance) {
      scrollTriggerInstance.kill();
    }

    scrollTriggerInstance = ScrollTrigger.create({
      // scroller: window.scrollWrap,
      trigger: toggle,
      start: "top 90%",
      onEnter: () => hideItems(),
      onLeaveBack: () => revealItems(),
    });
  }

  createScrollTrigger();

  return {
    hideItems,
    revealItems,
    toggleDock,
    setExtraOffset: (newOffset) => {
      extraOffset = newOffset;
    },
    setInitialState,
    recreateScrollTrigger: createScrollTrigger,
  };
}

function ensureGlobalFunctions() {
  if (!newsDockingInstance) {
    newsDockingInstance = initNewsDocking();
  }

  window.setInitialNews = newsDockingInstance.setInitialState;
  window.hideNewsItems = newsDockingInstance.hideItems;
  window.revealNewsItems = newsDockingInstance.revealItems;
  window.toggleNewsDock = newsDockingInstance.toggleDock;
}

export function initNews() {
  ensureGlobalFunctions();

  marqueeInstance = createMarquee({
    baseSpeed: 0.6,
  });

  newsDockingInstance.recreateScrollTrigger();

  window.setInitialNews();
}

export function destroyNews() {
  if (marqueeInstance) {
    // Stop the marquee animation
    window.pauseMarquee();

    // Remove event listeners
    const track = marqueeInstance.track;
    track.removeEventListener("mouseenter", marqueeInstance.setTargetSpeed);
    track.removeEventListener("mouseleave", marqueeInstance.setTargetSpeed);
    track.removeEventListener("mousedown", marqueeInstance.startDragging);
    document.removeEventListener("mousemove", marqueeInstance.drag);
    document.removeEventListener("mouseup", marqueeInstance.stopDragging);
    document.removeEventListener("mouseleave", marqueeInstance.stopDragging);

    // Remove drag prevention from child elements
    track.querySelectorAll("*").forEach((element) => {
      element.removeEventListener("dragstart", (e) => e.preventDefault());
    });

    // Reset the track styles
    gsap.set(track, {
      clearProps: "all",
    });

    // Remove cloned items
    const originalItemCount = marqueeInstance.items.length;
    while (track.children.length > originalItemCount) {
      track.removeChild(track.lastChild);
    }

    marqueeInstance = null;
  }

  if (newsDockingInstance) {
    const marquee = document.querySelector('[news-marquee="wrap"]');
    const items = Array.from(marquee.querySelectorAll(".news__pill--w"));
    const button = marquee.querySelector(".news__btn--w");

    // Reset styles
    gsap.set([marquee, items, button], {
      clearProps: "all",
    });

    newsDockingInstance = null;
  }

  if (scrollTriggerInstance) {
    scrollTriggerInstance.kill();
    scrollTriggerInstance = null;
  }

  // Remove global functions
  delete window.pauseMarquee;
  delete window.playMarquee;
  delete window.setMarqueeSpeed;
  delete window.setInitialNews;
  delete window.hideNewsItems;
  delete window.revealNewsItems;
  delete window.toggleNewsDock;

  // Reset GSAP animations
  gsap.killTweensOf([
    '[news-marquee="wrap"]',
    '[news-marquee="track"]',
    ".news__pill--w",
    ".news__btn--w",
  ]);
}
