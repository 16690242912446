import { initSubHero, aboutSubHeroIn, destroySubHero } from "./hero";
import { initHlAnims, destroyHlAnims } from "../../modules/animations";
import { aboutSectionAnims, destroyAboutSectionAnims } from "./sections";
import { initTeamCards, destroyTeamCards } from "./team";

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initAbout(transition = false) {
  initHlAnims();
  aboutSectionAnims();
  initTeamCards();
  if (transition) {
    // transitioning to home
    initSubHero();
    setTimeout(aboutSubHeroIn, 50);
  } else {
    // no transition
    initSubHero();
  }
  if (!window.isTabletOrBelow) {
  }
}

export function destroyAbout() {
  destroySubHero();
  destroyHlAnims();
  destroyTeamCards();
  destroyAboutSectionAnims();
}
