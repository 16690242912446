import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

export function runUtils() {
  console.log("utils");
}

// ****
// ------------------------------ handle resize
// ****
let splitInstances = [];

export function onResize() {
  // Revert all SplitType instances
  // splitInstances.forEach((instance) => {
  //   instance.revert();
  // });
  // // Clear the array after reverting
  // splitInstances = [];
  // // Run globalSplit again
  // globalSplit();
}

export function callAfterResize(func, delay) {
  let dc = gsap.delayedCall(delay || 0.2, func).pause(),
    handler = () => dc.restart(true);
  window.addEventListener("resize", handler);
  return handler; // in case you want to window.removeEventListener() later
}
// callAfterResize(onResize);

// ****
// ------------------------------ split function
// ****
export function runSplit(selector, type) {
  return new SplitType(selector, {
    types: type,
  });
}

// ****
// ------------------------------ split all text elements
// ****
export function globalSplit() {
  runSplit("[split-text]", "lines, words");
}

export function charsSplit() {
  runSplit("[split-chars]", "lines, chars");
}

// ****
// ------------------------------ Refresh scroll triggers
// ****
export function refreshAllScrollTriggers() {
  ScrollTrigger.refresh(true);

  if (window.SScroll && typeof window.SScroll.reconfig === "function") {
    window.SScroll.reconfig();
  }
  window.dispatchEvent(new CustomEvent("scrollTriggersRefreshed"));
}

// ****
// ------------------------------ remove edit mode
// ****
export function removeEditMode() {
  var body = document.body;
  if (body.hasAttribute("data-edit")) {
    body.removeAttribute("data-edit");
  }
}

// ****
// ------------------------------ detect current page
// ****
export function detectCurrentPage() {
  var currentPath = window.location.pathname;
  var nav = document.querySelector("[data-nav]");
  var navLinks = nav.querySelectorAll("[data-nav-link]");

  // Remove 'is--active' class from all navLinks
  navLinks.forEach(function (navLink) {
    navLink.classList.remove("is--active");
  });

  // Add 'is--active' class to the navLink that matches the currentPath
  navLinks.forEach(function (navLink) {
    if (navLink.getAttribute("href") === currentPath) {
      navLink.classList.add("is--active");
    }
  });
}

// ****
// ------------------------------ detect safari
// ****
export function detectSafari() {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    // do something else
    document.documentElement.classList.add("is--safari");
  }
}

// ****
// ------------------------------ reinit webflow
// ****
export function reinitWebflow() {
  const w = window.Webflow;
  w.destroy();
  w.ready();
}

// ****
// ------------------------------ reload page when switching between
// ------------------------------ tablet and desktop
// ****
export function reloadOnResize() {
  let previousWidth = window.innerWidth;
  window.onresize = function () {
    const currentWidth = window.innerWidth;
    if (previousWidth < 991 !== currentWidth < 991) {
      location.reload();
    }
    previousWidth = currentWidth;
  };
}

// ****
// ------------------------------ handle preferes reduced motion
// ****
// if (!window.prm) {}
window.prm = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

// ****
// ------------------------------ handle tablet cut off
// ****
// if (!window.isTabletOrBelow) {}
const tabletMediaQuery = window.matchMedia("(max-width: 991px)");
window.isTabletOrBelow = tabletMediaQuery.matches;
tabletMediaQuery.addEventListener("change", (event) => {
  window.isTabletOrBelow = event.matches;
});

// ****
// ------------------------------ handle mobile cut off
const mobileMediaQuery = window.matchMedia("(max-width: 479px)");
window.isMob = mobileMediaQuery.matches;
mobileMediaQuery.addEventListener("change", (event) => {
  window.isMob = event.matches;
});

// ****
// ------------------------------ welcome message
// ****
export function runWelcome() {
  let msg = "%c ☩ Site built by, https://itsoffbrand.com ☩";
  let styles = [
    "font-size: 12px",
    "color: #fffce1",
    "font-family: monospace",
    "background: #0e100f",
    "display: inline-block",
    "padding: 1rem 3rem",
    "border: 1px solid #fffce1",
    "border-radius: 4px;",
  ].join(";");
  console.log(msg, styles);
}

// ****
// ------------------------------ forms
// ****
export function initFormSubmits() {
  const formParents = document.querySelectorAll('[data-nlform="parent"]');

  formParents.forEach((parent) => {
    const triggerButton = parent?.querySelector('[data-nlform="trigger"]');
    const buttonTextElement = parent?.querySelector('[data-nlform="text"]');
    const hiddenSubmitButton = parent?.querySelector('[data-nlform="submit"]');

    if (triggerButton && buttonTextElement && hiddenSubmitButton) {
      // Create the event listener function
      const handleSubmit = function (event) {
        event.preventDefault();

        // Change text of buttonTextElement
        const waitText =
          hiddenSubmitButton.getAttribute("data-wait") || "Please wait...";
        buttonTextElement.textContent = waitText;

        // Trigger click on hidden submit button
        hiddenSubmitButton.click();
      };

      // Attach the event listener to triggerButton
      triggerButton.addEventListener("click", handleSubmit);

      // Store the event listener for later removal
      triggerButton._eventListener = handleSubmit;
    }
  });
}

export function destroyFormSubmits() {
  const formParents = document.querySelectorAll('[data-nlform="parent"]');

  formParents.forEach((parent) => {
    const triggerButton = parent?.querySelector('[data-nlform="trigger"]');

    if (triggerButton && triggerButton._eventListener) {
      // Remove the event listener from triggerButton
      triggerButton.removeEventListener("click", triggerButton._eventListener);
      delete triggerButton._eventListener;
    }
  });
}

// ****
// ------------------------------ hubspot
// ****
export function loadHubSpotScript() {
  // Check if the script is already loaded
  if (!document.getElementById("hs-script-loader")) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    script.src = "//js.hs-scripts.com/44321480.js";
    document.body.appendChild(script);
  }
}

export function destroyHubSpotScript() {
  const script = document.getElementById("hs-script-loader");
  if (script) {
    document.body.removeChild(script);
  }
}

export function setVh() {
  // Calculate 1% of the viewport height
  const vh = window.innerHeight * 0.01;
  // Set the value of --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

// Initial execution to set the value on page load
// setVh();

// Listen to the resize event to update the value on window resize
// window.addEventListener('resize', setVh);

// Optional: Listen to the orientationchange event for devices that support it
// window.addEventListener('orientationchange', setVh);
