import { initSubHero, subHeroAnimateIn, destroySubHero } from "./hero";
import { featuredMaskAnimate, destroyFeaturedMask } from "./featured";
import { initCmsFilter, destroyCmsFilter } from "./filter";
import { initHlAnims, destroyHlAnims } from "../../modules/animations";

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export async function initBlog(transition = false) {
  initCmsFilter();
  initHlAnims();
  featuredMaskAnimate();
  if (transition) {
    // transitioning to home
    initSubHero();
    setTimeout(subHeroAnimateIn, 50);
  } else {
    initSubHero();
  }
  if (!window.isTabletOrBelow) {
  }
}

export function destroyBlog() {
  destroyHlAnims();
  destroyCmsFilter();
  destroySubHero();
  destroyFeaturedMask();
}
