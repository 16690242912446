import { initHero, destroyHero, heroAnimateIn } from "./hero";
import { destroyCycle } from "./heroText";
import { initNews, destroyNews } from "./newsMarquee";
import { initCommunity, destroyCommunity } from "./community";
import { initRoadmap, destroyRoadmap } from "./roadmap";
import { initMission, destroyMission } from "./mission";
import { impactSectionIn, destroyImpact } from "./impact";
import { initEcosystem, destroyEcosystem } from "./ecosystem";
import { initProduct, destroyProduct } from "./product";
import { initProviderStats, destroyProviderStats } from "./providerStats";
import { partnerCardsIn, destroyPartnerCards } from "./partners";
import { initHlAnims, destroyHlAnims } from "../../modules/animations";
import { mountVideoModal, unmountVideoModal } from "./video";

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initHome(transition = false) {
  // Start all synchronous initializations
  if (!window.isTabletOrBelow) {
    initNews();
  }
  initCommunity();
  initRoadmap();
  initProduct();
  initMission();
  impactSectionIn();
  initHlAnims();
  initProviderStats();
  partnerCardsIn();
  // mountVideoModal();

  // Handle asynchronous initializations
  const ecosystemPromise = initEcosystem().catch((error) => {
    console.error("Failed to initialize ecosystem:", error);
  });

  if (transition) {
    initHero();
    setTimeout(heroAnimateIn, 1000);
  } else {
    setTimeout(initHero, 1000);
  }

  if (!window.isTabletOrBelow) {
    // Any non-tablet specific initializations
  }

  // Return a promise that resolves when all async operations are complete
  return Promise.all([ecosystemPromise]);
}

export function destroyHome() {
  destroyCycle();
  destroyHlAnims();
  destroyCommunity();
  destroyImpact();
  destroyNews();
  destroyHero();
  destroyPartnerCards();
  destroyRoadmap();
  destroyEcosystem();
  destroyProduct();
  destroyMission();
  destroyProviderStats();
  // unmountVideoModal();
}
