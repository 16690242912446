import gsap from "gsap";
import { lenisResize, lenisToTop } from "./scroll.js";
import {
  reinitWebflow,
  globalSplit,
  charsSplit,
  initFormSubmits,
  destroyFormSubmits,
  loadHubSpotScript,
  destroyHubSpotScript,
  setVh,
} from "./utils";
import { initNav, destroyNav, initFooter, destroyFooter } from "./nav.js";
import { initHome, destroyHome } from "../pages/home/init.js";
import { initBlog, destroyBlog } from "../pages/blog/init.js";
import {
  initBlogArticle,
  destroyBlogArticle,
} from "../pages/blog-article/init.js";
import { initAbout, destroyAbout } from "../pages/about/init.js";
import { initEnterprise, destroyEnterprise } from "../pages/enterprise/init.js";
import {
  transitionHide,
  transitionReveal,
  transitionSiteLoaded,
  preloader,
  initRiveMain,
  initRiveSub,
  destroyRiveSub,
} from "./rive.js";
import { setupScrollTriggerRefresh } from "./scrolltrigger.js";
import { mountVideoModal, unmountVideoModal } from "../pages/home/video.js";

import { initializeWorld, setupCallbacks, initWorld } from "./world.js";
export const transitionDuration = 1000;

// --------------------------------------------------------------------- TRANSITION IN
// --------------------------------------------------------------------- TRANSITION IN
// --------------------------------------------------------------------- TRANSITION IN

export function transInPersist(pageName) {
  let transition = true;
  window.scrollTo(0, 0);

  // Common initialization function
  const commonInit = () => {
    reinitWebflow();
    globalSplit();
    charsSplit();
    setTimeout(lenisResize, 1000);
    window.SScroll.reconfig();
    initFooter();
    initFormSubmits();
    // loadHubSpotScript();
    setupScrollTriggerRefresh();
    setVh();
    // --------------- SCROLL CONTROL
    if (window.SScroll && typeof window.SScroll.start === "function") {
      setTimeout(() => {
        window.SScroll.start();
      }, 1500);
    } else {
      console.error("SScroll.start is not available");
    }
  };

  if (pageName === "home") {
    initWorld();

    const checkWorldLoaded = () => {
      if (window.worldIsLoaded) {
        commonInit();
        transitionReveal();
        initHome(transition)
          .then(() => {
            // home loading complete
            // if (!window.isVideoModalMounted) {
            //   mountVideoModal();
            // }
            mountVideoModal();
          })
          .catch((error) => {
            console.error(
              "An error occurred during home initialization:",
              error
            );
          });
        initRiveSub();
      } else {
        requestAnimationFrame(checkWorldLoaded);
      }
    };

    checkWorldLoaded();
  } else {
    // For all other pages
    commonInit();
    transitionReveal();

    switch (pageName) {
      case "blog":
        initBlog(transition);
        break;
      case "blog-article":
        initBlogArticle(transition);
        break;
      case "about":
        initAbout(transition);
        break;
      case "enterprise":
        initEnterprise(transition);
        break;
      // Add other pages as needed
      default:
        console.warn(`No specific initialization for page: ${pageName}`);
    }
  }
}

// --------------------------------------------------------------------- TRANSITION OUT
// --------------------------------------------------------------------- TRANSITION OUT
// --------------------------------------------------------------------- TRANSITION OUT

export function transOutPersist(pageName) {
  transitionHide();
  destroyRiveSub();
  destroyFormSubmits();
  destroyFooter();
  // destroyHubSpotScript();
  // --------------- SCROLL CONTROL
  if (window.SScroll && typeof window.SScroll.stop === "function") {
    window.SScroll.stop();
  } else {
    console.error("SScroll.stop is not available");
  }
  setTimeout(() => {
    switch (pageName) {
      case "home":
        destroyHome();
        unmountVideoModal();
        break;
      case "blog":
        destroyBlog();
        break;
      case "blog-article":
        destroyBlogArticle();
        break;
      case "about":
        destroyAbout();
        break;
      case "enterprise":
        destroyEnterprise();
        break;
      // Add other pages as needed
    }
  }, transitionDuration);
}

// --------------------------------------------------------------------- FIRST ENTRY
// --------------------------------------------------------------------- FIRST ENTRY
// --------------------------------------------------------------------- FIRST ENTRY

export function onStart() {
  const initPage = document.querySelector("[data-page]").dataset.page;
  const initStart = document.querySelector('[data-start="hidden"]');

  // Critical initializations
  initNav();
  initFooter();

  // Show initial content
  gsap.to(initStart, {
    delay: 0.1,
    autoAlpha: 1,
    duration: 0.3,
  });

  document.body.style.cursor = "progress";

  // Common non-critical initializations
  const commonInit = () => {
    globalSplit();
    charsSplit();
    initFormSubmits();
    // loadHubSpotScript();
    setupScrollTriggerRefresh();
    setVh();

    if (initPage !== "home") {
      if (window.SScroll && typeof window.SScroll.start === "function") {
        window.SScroll.start();
        // console.log("SScroll.start other pages");
      } else {
        console.error("SScroll.start is not available");
      }
    }
  };

  // Use requestIdleCallback if available, fall back to setTimeout
  const scheduleDeferred = (callback) => {
    if ("requestIdleCallback" in window) {
      requestIdleCallback(callback, { timeout: 5000 });
    } else {
      setTimeout(callback, 0);
    }
  };

  // Initialize Rive for all pages, and World only for home page
  initRiveMain();
  if (initPage === "home") {
    initWorld();
    mountVideoModal();
  }

  // --------------- SCROLL CONTROL
  if (window.SScroll && typeof window.SScroll.stop === "function") {
    window.SScroll.stop();
  } else {
    console.error("SScroll.stop is not available");
  }

  const checkInitConditions = () => {
    const isReady =
      document.readyState === "complete" && window.riveAssetsLoaded;
    const isHomeReady = initPage === "home" ? window.worldIsLoaded : true;

    if (isReady && isHomeReady) {
      scheduleDeferred(commonInit);
      scheduleDeferred(() => {
        switch (initPage) {
          case "home":
            initHome()
              .then(() => {
                // home loading
              })
              .catch((error) => {
                console.error(
                  "An error occurred during home initialization:",
                  error
                );
              });
            initRiveSub();
            break;
          case "blog":
            initBlog();
            break;
          case "blog-article":
            initBlogArticle();
            break;
          case "about":
            initAbout();
            break;
          case "enterprise":
            initEnterprise();
            break;
          // Add other pages as needed
        }
        transitionSiteLoaded();
        // console.log(`${initPage} loaded`);
      });
    } else {
      requestAnimationFrame(checkInitConditions);
    }
  };

  // Start checking conditions
  checkInitConditions();
}
