import { gsap } from "gsap";

class OBVideoPlayer {
  constructor(options = {}) {
    this.videoPlayers = [];
    this.options = options;
    this.init();
  }

  init() {
    const parents = document.querySelectorAll("[ob-video-parent]");
    parents.forEach((parent) => {
      const videoOptions = this.getVideoOptions(parent);
      const videoContainer = this.createVideoContainer(videoOptions);
      parent.appendChild(videoContainer);

      // Apply event listeners and store references
      const eventListeners = this.applyEventListeners(
        parent,
        videoContainer,
        videoOptions
      );

      // Store the event listeners in the videoPlayers array
      this.videoPlayers.push({
        parent,
        videoContainer,
        videoOptions,
        eventListeners,
      });
    });
  }

  getVideoOptions(parent) {
    const sources = [];
    let i = 1;
    while (parent.hasAttribute(`ob-video-src${i}`)) {
      sources.push(parent.getAttribute(`ob-video-src${i}`));
      i++;
    }

    return {
      lazyLoad: parent.getAttribute("ob-video-lazy") === "true",
      poster: parent.getAttribute("ob-video-poster") || "",
      playOnEnter: parent.getAttribute("ob-video-play-on-enter") !== "false",
      pauseOnLeave: parent.getAttribute("ob-video-pause-on-leave") === "true",
      pauseOnTabChange:
        parent.getAttribute("ob-video-pause-on-tab-change") === "true",
      loop: parent.getAttribute("ob-video-loop") === "true",
      sources: sources,
      controls: {
        playButton: true,
        volumeControl: true,
        progressBar: true,
        fullscreenToggle: true,
        ...this.options.controls,
      },
      icons: {
        play: parent.getAttribute("ob-video-icon-play") || null,
        pause: parent.getAttribute("ob-video-icon-pause") || null,
        volumeOn: parent.getAttribute("ob-video-icon-volume-on") || null,
        volumeOff: parent.getAttribute("ob-video-icon-volume-off") || null,
        fullscreen: parent.getAttribute("ob-video-icon-fullscreen") || null,
        fullscreenExit:
          parent.getAttribute("ob-video-icon-fullscreen-exit") || null,
        overlayPlay: parent.getAttribute("ob-video-icon-overlay-play") || null,
        ...this.options.icons,
      },
      colors: {
        playButtonBackground:
          parent.getAttribute("ob-video-color-play-button-bg") || "#000000",
        playButtonIcon:
          parent.getAttribute("ob-video-color-play-button-icon") || "#FFFFFF",
        progressBar:
          parent.getAttribute("ob-video-color-progress-bar") || "#FF0000",
        progressBarHandle:
          parent.getAttribute("ob-video-color-progress-handle") || "#FFFFFF",
        volumeControl:
          parent.getAttribute("ob-video-color-volume-control") || "#FFFFFF",
        controlsUnderlay:
          parent.getAttribute("ob-video-color-controls-underlay") ||
          "rgba(0, 0, 0, 0.5)",
        overlayPlayButtonBackground:
          parent.getAttribute("ob-video-color-overlay-play-button-bg") ||
          "#000000",
        overlayPlayButtonIcon:
          parent.getAttribute("ob-video-color-overlay-play-button-icon") ||
          "#FFFFFF",
        overlayPlayButtonContainerBackground:
          parent.getAttribute(
            "ob-video-color-overlay-play-button-container-bg"
          ) || "rgba(0, 0, 0, 0.5)",
        ...this.options.colors,
      },
    };
  }

  // Method to retrieve default SVG icons
  getDefaultIcon(iconName) {
    const icons = {
      play: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 16 16"><path fill="var(--ob-icon-color)" d="M13.313 5.554a2.203 2.203 0 0 1 0 3.892l-9.417 5.12c-1.516.825-3.378-.248-3.378-1.945V2.379C.518.682 2.38-.39 3.896.433l9.417 5.121Z"/></svg>`,
      pause: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 16 16"><path fill="var(--ob-icon-color)" d="M.15 3.09c0-1.386 0-2.079.43-2.51C1.012.15 1.705.15 3.09.15c1.386 0 2.079 0 2.51.43.43.431.43 1.124.43 2.51v8.82c0 1.386 0 2.079-.43 2.51-.431.43-1.124.43-2.51.43-1.386 0-2.079 0-2.51-.43-.43-.431-.43-1.124-.43-2.51V3.09ZM8.97 3.09c0-1.386 0-2.079.43-2.51.431-.43 1.124-.43 2.51-.43 1.386 0 2.079 0 2.51.43.43.431.43 1.124.43 2.51v8.82c0 1.386 0 2.079-.43 2.51-.431.43-1.124.43-2.51.43-1.386 0-2.079 0-2.51-.43-.43-.431-.43-1.124-.43-2.51V3.09Z"/></svg>`,
      volumeOn: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 16 16"><path fill="var(--ob-icon-color)" d="M.153 7.268C.184 5.763.2 5.01.723 4.367a2.62 2.62 0 0 1 .351-.352c.642-.519 1.489-.519 3.183-.519.605 0 .908 0 1.196-.076.06-.015.12-.034.178-.054.282-.099.535-.27 1.04-.61C8.665 1.414 9.662.743 10.5 1.034a1.8 1.8 0 0 1 .454.236c.724.52.779 1.728.889 4.144.04.894.068 1.66.068 2.086 0 .426-.028 1.192-.069 2.086-.11 2.416-.164 3.625-.888 4.144-.138.1-.294.18-.454.236-.837.292-1.834-.38-3.828-1.722-.505-.34-.758-.51-1.04-.61a2.304 2.304 0 0 0-.178-.054c-.288-.076-.59-.076-1.196-.076-1.694 0-2.541 0-3.183-.519a2.623 2.623 0 0 1-.352-.352C.2 9.99.184 9.237.152 7.733a10.894 10.894 0 0 1 0-.465Z"/><path fill="var(--ob-icon-color)" fill-rule="evenodd" d="M13.584 4.573a.582.582 0 0 1 .8.233l-.514.292.514-.292v.001l.001.002.001.002.004.006.007.014a1.642 1.642 0 0 1 .08.183c.046.118.103.285.159.505.11.442.214 1.092.214 1.981 0 .89-.104 1.54-.214 1.981a4.19 4.19 0 0 1-.159.506 2.443 2.443 0 0 1-.08.182l-.008.015-.003.006v.002l-.001.001s-.001.001-.515-.29l.514.291a.581.581 0 0 1-.8.233.607.607 0 0 1-.232-.809l.004-.008c.005-.011.016-.034.03-.07.027-.07.068-.187.11-.357.086-.34.178-.89.178-1.683 0-.792-.092-1.343-.178-1.682a2.979 2.979 0 0 0-.14-.427l-.004-.009a.607.607 0 0 1 .232-.809Z" clip-rule="evenodd"/></svg>`,
      volumeOff: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 16 16"><path fill="var(--ob-icon-color)" fill-rule="evenodd" d="M13.492 3.614a.513.513 0 0 1 .679.257c.32.707.679 1.892.679 3.629 0 1.498-.268 2.586-.547 3.312-.14.362-.28.633-.391.817a3.237 3.237 0 0 1-.198.29l-.005.007-.002.003s-.001.002-.395-.327l.394.329a.513.513 0 0 1-.79-.654l.002-.003a2.236 2.236 0 0 0 .115-.173c.08-.136.196-.353.313-.657.233-.608.478-1.57.478-2.944 0-1.59-.328-2.632-.588-3.208a.513.513 0 0 1 .256-.678ZM12.056 5.63a.513.513 0 0 1 .607.398c.075.363.136.85.136 1.472 0 .759-.09 1.315-.187 1.691a3.534 3.534 0 0 1-.19.551l-.018.037-.007.012-.002.005-.002.002V9.8l-.449-.248.448.25a.513.513 0 0 1-.9-.493l.004-.006a2.498 2.498 0 0 0 .123-.365c.074-.29.154-.76.154-1.437a6.24 6.24 0 0 0-.115-1.263.513.513 0 0 1 .398-.606Z" clip-rule="evenodd"/><path fill="var(--ob-icon-color)" d="M14.358 1.71a.513.513 0 0 0-.725-.726L10.59 4.026a7.693 7.693 0 0 0-.133-.95c-.117-.539-.325-1.008-.763-1.315a2.097 2.097 0 0 0-.525-.267c-.56-.191-1.116-.039-1.68.236C6.933 2 6.258 2.445 5.417 3l-.183.12c-.306.202-.411.27-.52.318-.117.051-.24.088-.365.11-.117.019-.243.02-.61.02h-.11c-.832 0-1.41 0-1.924.238-.475.22-.932.65-1.18 1.11-.269.499-.3 1.02-.345 1.76l-.005.09c-.016.252-.025.5-.025.734 0 .234.01.483.025.734l.005.09c.045.74.076 1.261.345 1.76.248.46.705.89 1.18 1.11.418.194.878.23 1.482.236l-1.861 1.86a.513.513 0 1 0 .725.726L14.358 1.71ZM10.748 7.5a.513.513 0 0 0-.859-.379l-.048.051-4.102 4.29a.532.532 0 0 0 .112.824c.71.466 1.299.837 1.8 1.06.507.224 1.011.333 1.519.16a2.1 2.1 0 0 0 .525-.267c.483-.34.687-.875.798-1.49.11-.605.146-1.406.193-2.405l.001-.039c.036-.762.06-1.428.06-1.805Z"/></svg>`,
      fullscreen: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 16 16"><path fill="var(--ob-icon-color)" fill-rule="evenodd" d="M1.226 1.226C.15 2.303.15 4.036.15 7.5c0 3.465 0 5.197 1.076 6.274C2.303 14.85 4.036 14.85 7.5 14.85c3.465 0 5.197 0 6.274-1.076 1.076-1.077 1.076-2.81 1.076-6.274 0-3.465 0-5.197-1.076-6.274C12.697.15 10.964.15 7.5.15c-3.465 0-5.197 0-6.274 1.076ZM8.42 3.825c0 .304.247.551.551.551h.874L8.213 6.008a.551.551 0 1 0 .78.78l1.63-1.632v.874a.551.551 0 0 0 1.103 0V3.825a.551.551 0 0 0-.551-.551H8.97a.551.551 0 0 0-.551.551ZM6.787 8.992a.551.551 0 1 0-.78-.78l-1.63 1.632V8.97a.551.551 0 0 0-1.103 0v2.205c0 .304.247.551.551.551H6.03a.551.551 0 0 0 0-1.102h-.874l1.631-1.632Z" clip-rule="evenodd"/></svg>`,
      fullscreenExit: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 16 16"><path fill="var(--ob-icon-color)" d="M.15 7.5c0-3.465 0-5.197 1.076-6.274C2.303.15 4.036.15 7.5.15c3.038 0 4.745 0 5.845.726L9.52 4.699V3.274a.551.551 0 0 0-1.102 0V6.03c0 .304.247.551.551.551h2.756a.551.551 0 0 0 0-1.102h-1.425l3.823-3.824c.726 1.1.726 2.806.726 5.845 0 3.465 0 5.197-1.076 6.274-1.077 1.076-2.81 1.076-6.274 1.076-3.038 0-4.745 0-5.845-.726l3.824-3.823v1.425a.551.551 0 0 0 1.102 0V8.97a.551.551 0 0 0-.551-.551H3.274a.551.551 0 0 0 0 1.102h1.425L.876 13.344C.15 12.245.15 10.538.15 7.5Z"/></svg>`,
      overlayPlay: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 16 16"><path fill="var(--ob-icon-color)" d="M13.313 5.554a2.203 2.203 0 0 1 0 3.892l-9.417 5.12c-1.516.825-3.378-.248-3.378-1.945V2.379C.518.682 2.38-.39 3.896.433l9.417 5.121Z"/></svg>`,
    };
    return icons[iconName] || "";
  }

  createVideoContainer(options) {
    const videoContainer = document.createElement("div");
    videoContainer.className = "ob-video-wrapper";
    videoContainer.style.position = "relative";

    const videoWrapper = document.createElement("div");
    videoWrapper.className = "ob-video-aspect-ratio";
    videoWrapper.style.position = "relative";
    videoWrapper.style.width = "100%";
    videoWrapper.style.height = "0";
    videoWrapper.style.paddingBottom = "56.25%"; // Default to 16:9 aspect ratio
    videoWrapper.style.overflow = "hidden";

    const video = document.createElement("video");
    video.className = "ob-video-element";
    video.setAttribute("preload", options.lazyLoad ? "none" : "auto");
    video.setAttribute("poster", options.poster);
    video.setAttribute("tabindex", "0");
    video.setAttribute("aria-label", "Video Player");
    video.style.position = "absolute";
    video.style.top = "0";
    video.style.left = "0";
    video.style.width = "100%";
    video.style.height = "100%";
    video.style.objectFit = "cover";
    video.style.display = "block";

    if (options.loop) {
      video.setAttribute("loop", "");
    }

    // Add sources
    options.sources.forEach((src) => {
      const sourceElement = document.createElement("source");
      sourceElement.setAttribute("src", src);
      video.appendChild(sourceElement);
    });

    // Overlay Play Button if playOnEnter is false
    let overlayContainer = null;
    if (!options.playOnEnter) {
      overlayContainer = document.createElement("div");
      overlayContainer.className = "ob-overlay-container";
      overlayContainer.style.background =
        options.colors.overlayPlayButtonContainerBackground;
      overlayContainer.style.zIndex = "10"; // Higher z-index

      const overlayPlayButton = document.createElement("button");
      overlayPlayButton.className = "ob-overlay-play-button";
      overlayPlayButton.setAttribute("aria-label", "Play Video");

      // Use SVG icon
      overlayPlayButton.innerHTML = options.icons.overlayPlay
        ? `<img src="${options.icons.overlayPlay}" alt="Play" />`
        : this.getDefaultIcon("overlayPlay");

      overlayPlayButton.onclick = () => {
        video.play();
        overlayContainer.style.display = "none";
        videoContainer.classList.add("overlay-hidden");
        videoContainer.classList.remove("overlay-visible");
      };

      overlayContainer.onclick = () => {
        video.play();
        overlayContainer.style.display = "none";
        videoContainer.classList.add("overlay-hidden");
        videoContainer.classList.remove("overlay-visible");
      };

      overlayContainer.appendChild(overlayPlayButton);
      videoWrapper.appendChild(overlayContainer);

      // Initially, add the 'overlay-visible' class
      videoContainer.classList.add("overlay-visible");
    } else {
      // Add 'overlay-hidden' class when playOnEnter is true
      videoContainer.classList.add("overlay-hidden");
    }

    // Add loading indicator
    const loadingIndicator = document.createElement("div");
    loadingIndicator.className = "ob-loading-indicator";
    loadingIndicator.setAttribute("aria-hidden", "true");
    loadingIndicator.style.display = "none";

    // Add spinner element inside the loading indicator
    loadingIndicator.innerHTML = `<div class="ob-spinner"></div>`;

    // Add error message container
    const errorMessage = document.createElement("div");
    errorMessage.className = "ob-error-message";
    errorMessage.setAttribute("role", "alert");
    errorMessage.style.display = "none";

    // Add custom controls
    const controlsContainer = this.createControls(options);

    videoWrapper.appendChild(video);
    videoContainer.appendChild(videoWrapper);
    videoContainer.appendChild(loadingIndicator);
    videoContainer.appendChild(errorMessage);
    videoContainer.appendChild(controlsContainer);

    // Adjust aspect ratio when metadata is loaded
    video.addEventListener("loadedmetadata", () => {
      const aspectRatio = (video.videoHeight / video.videoWidth) * 100;
      videoWrapper.style.paddingBottom = `${aspectRatio}%`;
    });

    return videoContainer;
  }

  createControls(options) {
    const controls = document.createElement("div");
    controls.className = "ob-video-controls";
    controls.style.background = options.colors.controlsUnderlay;
    controls.style.opacity = "0";
    controls.style.transition = "opacity 0.3s";

    // Left Controls
    const leftControls = document.createElement("div");
    leftControls.className = "ob-left-controls";

    // Right Controls
    const rightControls = document.createElement("div");
    rightControls.className = "ob-right-controls";

    // Play/Pause Button
    if (options.controls.playButton) {
      const playButton = document.createElement("button");
      playButton.className = "ob-play-button";
      playButton.setAttribute("aria-label", "Play");

      // Use SVG icon
      playButton.innerHTML = options.icons.play
        ? `<img src="${options.icons.play}" alt="Play" />`
        : this.getDefaultIcon("play");

      playButton.onclick = () => this.togglePlayPause(playButton);
      leftControls.appendChild(playButton);
    }

    // Progress Bar
    if (options.controls.progressBar) {
      const progressContainer = document.createElement("div");
      progressContainer.className = "ob-progress-container";

      const progressBar = document.createElement("input");
      progressBar.className = "ob-progress-bar";
      progressBar.setAttribute("type", "range");
      progressBar.setAttribute("min", "0");
      progressBar.setAttribute("max", "100");
      progressBar.setAttribute("value", "0");
      progressBar.setAttribute("aria-label", "Seek");
      progressBar.oninput = () => this.seekVideo(progressBar);

      progressContainer.appendChild(progressBar);
      leftControls.appendChild(progressContainer);
    }

    // Volume Control
    if (options.controls.volumeControl) {
      const volumeContainer = document.createElement("div");
      volumeContainer.className = "ob-volume-container";

      const volumeIcon = document.createElement("button"); // Changed to button
      volumeIcon.className = "ob-volume-icon";
      volumeIcon.setAttribute("aria-label", "Mute");

      // Use SVG icon
      volumeIcon.innerHTML = options.icons.volumeOn
        ? `<img src="${options.icons.volumeOn}" alt="Volume On" />`
        : this.getDefaultIcon("volumeOn");

      volumeIcon.onclick = () => this.toggleMute(volumeRange, volumeIcon);

      const volumeRange = document.createElement("input");
      volumeRange.className = "ob-volume-range";
      volumeRange.setAttribute("type", "range");
      volumeRange.setAttribute("min", "0");
      volumeRange.setAttribute("max", "1");
      volumeRange.setAttribute("step", "0.01");
      volumeRange.setAttribute("value", "1");
      volumeRange.setAttribute("aria-label", "Volume");
      volumeRange.oninput = () => this.changeVolume(volumeRange, volumeIcon);

      volumeContainer.appendChild(volumeIcon);
      volumeContainer.appendChild(volumeRange);
      leftControls.appendChild(volumeContainer);
    }

    // Fullscreen Toggle
    if (options.controls.fullscreenToggle) {
      const fullscreenButton = document.createElement("button");
      fullscreenButton.className = "ob-fullscreen-button";
      fullscreenButton.setAttribute("aria-label", "Enter Fullscreen");

      // Use SVG icon
      fullscreenButton.innerHTML = options.icons.fullscreen
        ? `<img src="${options.icons.fullscreen}" alt="Fullscreen" />`
        : this.getDefaultIcon("fullscreen");

      fullscreenButton.onclick = () => this.toggleFullscreen(fullscreenButton);
      rightControls.appendChild(fullscreenButton);
    }

    controls.appendChild(leftControls);
    controls.appendChild(rightControls);

    return controls;
  }

  applyEventListeners(parent, videoContainer, options) {
    const video = videoContainer.querySelector(".ob-video-element");
    const playButton = videoContainer.querySelector(".ob-play-button");
    const progressBar = videoContainer.querySelector(".ob-progress-bar");
    const volumeRange = videoContainer.querySelector(".ob-volume-range");
    const volumeIcon = videoContainer.querySelector(".ob-volume-icon");
    const loadingIndicator = videoContainer.querySelector(
      ".ob-loading-indicator"
    );
    const errorMessage = videoContainer.querySelector(".ob-error-message");
    const controls = videoContainer.querySelector(".ob-video-controls");
    const overlayContainer = videoContainer.querySelector(
      ".ob-overlay-container"
    );
    const fullscreenButton = videoContainer.querySelector(
      ".ob-fullscreen-button"
    );

    const eventListeners = {
      video: [],
      progressBar: [],
      volumeRange: [],
      videoContainer: [],
      document: [],
      observer: null,
    };

    // Viewport-Based Play/Pause using Intersection Observer
    if (options.playOnEnter || options.pauseOnLeave) {
      const observerOptions = {
        root: null,
        rootMargin: "0px",
        threshold: 0.25, // Adjust threshold as needed
      };

      const observerCallback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (
              options.playOnEnter &&
              video.paused &&
              !video.dataset.userPaused
            ) {
              video.play();
            }
          } else {
            if (options.pauseOnLeave && !video.paused) {
              video.pause();
            }
          }
        });
      };

      const observer = new IntersectionObserver(
        observerCallback,
        observerOptions
      );
      observer.observe(videoContainer);

      eventListeners.observer = observer;
    }

    // Pause on Tab Change
    let onVisibilityChange = null;
    if (options.pauseOnTabChange) {
      onVisibilityChange = () => {
        if (document.hidden && !video.paused) {
          video.pause();
          video.dataset.tabPaused = true;
        } else if (video.dataset.tabPaused) {
          video.play();
          delete video.dataset.tabPaused;
        }
      };
      document.addEventListener("visibilitychange", onVisibilityChange);
      eventListeners.document.push({
        type: "visibilitychange",
        handler: onVisibilityChange,
      });
    }

    // Video Event Listeners

    const onVideoPlay = () => {
      if (playButton) {
        playButton.innerHTML = options.icons.pause
          ? `<img src="${options.icons.pause}" alt="Pause" />`
          : this.getDefaultIcon("pause");
        playButton.setAttribute("aria-label", "Pause");
      }
      loadingIndicator.style.display = "none";
      delete video.dataset.userPaused;
      if (overlayContainer) {
        overlayContainer.style.display = "none";
        videoContainer.classList.add("overlay-hidden");
        videoContainer.classList.remove("overlay-visible");
      }
    };

    video.addEventListener("play", onVideoPlay);
    eventListeners.video.push({ type: "play", handler: onVideoPlay });

    const onVideoPause = () => {
      if (playButton) {
        playButton.innerHTML = options.icons.play
          ? `<img src="${options.icons.play}" alt="Play" />`
          : this.getDefaultIcon("play");
        playButton.setAttribute("aria-label", "Play");
      }
      if (!video.dataset.tabPaused) {
        video.dataset.userPaused = true;
      }
      if (overlayContainer && video.currentTime === 0 && !options.playOnEnter) {
        overlayContainer.style.display = "flex";
        videoContainer.classList.remove("overlay-hidden");
        videoContainer.classList.add("overlay-visible");
      }
    };

    video.addEventListener("pause", onVideoPause);
    eventListeners.video.push({ type: "pause", handler: onVideoPause });

    const onVideoTimeUpdate = () => {
      if (progressBar) {
        const progress = (video.currentTime / video.duration) * 100;
        progressBar.value = progress;
      }
    };

    video.addEventListener("timeupdate", onVideoTimeUpdate);
    eventListeners.video.push({
      type: "timeupdate",
      handler: onVideoTimeUpdate,
    });

    const onVideoWaiting = () => {
      loadingIndicator.style.display = "block";
    };

    video.addEventListener("waiting", onVideoWaiting);
    eventListeners.video.push({ type: "waiting", handler: onVideoWaiting });

    const onVideoPlaying = () => {
      loadingIndicator.style.display = "none";
      if (overlayContainer) {
        overlayContainer.style.display = "none";
        videoContainer.classList.add("overlay-hidden");
        videoContainer.classList.remove("overlay-visible");
      }
    };

    video.addEventListener("playing", onVideoPlaying);
    eventListeners.video.push({ type: "playing", handler: onVideoPlaying });

    const onVideoError = () => {
      errorMessage.style.display = "block";
      errorMessage.innerHTML = "An error occurred during video playback.";
    };

    video.addEventListener("error", onVideoError);
    eventListeners.video.push({ type: "error", handler: onVideoError });

    // Progress Bar Update
    if (progressBar) {
      const onProgressBarInput = () => {
        const seekTime = (progressBar.value / 100) * video.duration;
        video.currentTime = seekTime;
      };

      progressBar.addEventListener("input", onProgressBarInput);
      eventListeners.progressBar.push({
        type: "input",
        handler: onProgressBarInput,
      });
    }

    // Volume Control
    if (volumeRange) {
      const onVolumeRangeInput = () => {
        this.changeVolume(volumeRange, volumeIcon);
      };

      volumeRange.addEventListener("input", onVolumeRangeInput);
      eventListeners.volumeRange.push({
        type: "input",
        handler: onVolumeRangeInput,
      });
    }

    // Show/Hide Controls on Hover
    const onVideoContainerMouseEnter = () => {
      if (!overlayContainer || overlayContainer.style.display === "none") {
        controls.style.opacity = "1";
      }
    };

    videoContainer.addEventListener("mouseenter", onVideoContainerMouseEnter);
    eventListeners.videoContainer.push({
      type: "mouseenter",
      handler: onVideoContainerMouseEnter,
    });

    const onVideoContainerMouseLeave = () => {
      controls.style.opacity = "0";
    };

    videoContainer.addEventListener("mouseleave", onVideoContainerMouseLeave);
    eventListeners.videoContainer.push({
      type: "mouseleave",
      handler: onVideoContainerMouseLeave,
    });

    // Keyboard Accessibility
    const onVideoContainerKeyDown = (e) => {
      switch (e.key) {
        case " ":
        case "k":
          e.preventDefault();
          this.togglePlayPause(playButton);
          break;
        case "m":
          e.preventDefault();
          this.toggleMute(volumeRange, volumeIcon);
          break;
        case "f":
          e.preventDefault();
          this.toggleFullscreen(fullscreenButton);
          break;
        case "ArrowRight":
          e.preventDefault();
          video.currentTime += 5;
          break;
        case "ArrowLeft":
          e.preventDefault();
          video.currentTime -= 5;
          break;
        case "ArrowUp":
          e.preventDefault();
          video.volume = Math.min(video.volume + 0.1, 1);
          volumeRange.value = video.volume;
          this.updateVolumeIcon(video, volumeIcon, options);
          break;
        case "ArrowDown":
          e.preventDefault();
          video.volume = Math.max(video.volume - 0.1, 0);
          volumeRange.value = video.volume;
          this.updateVolumeIcon(video, volumeIcon, options);
          break;
      }
    };

    videoContainer.addEventListener("keydown", onVideoContainerKeyDown);
    eventListeners.videoContainer.push({
      type: "keydown",
      handler: onVideoContainerKeyDown,
    });

    // Return the eventListeners object
    return eventListeners;
  }

  togglePlayPause(button) {
    const video = button
      .closest(".ob-video-wrapper")
      .querySelector(".ob-video-element");
    const videoContainer = button.closest(".ob-video-wrapper");
    const overlayContainer = videoContainer.querySelector(
      ".ob-overlay-container"
    );
    const options = this.videoPlayers.find(
      (vp) => vp.videoContainer === videoContainer
    ).videoOptions;

    if (video.paused) {
      video.play();
      if (overlayContainer) {
        overlayContainer.style.display = "none";
        videoContainer.classList.add("overlay-hidden");
        videoContainer.classList.remove("overlay-visible");
      }
    } else {
      video.pause();
    }
  }

  seekVideo(progressBar) {
    const video = progressBar
      .closest(".ob-video-wrapper")
      .querySelector(".ob-video-element");
    const seekTime = (progressBar.value / 100) * video.duration;
    video.currentTime = seekTime;
  }

  changeVolume(volumeRange, volumeIcon) {
    const video = volumeRange
      .closest(".ob-video-wrapper")
      .querySelector(".ob-video-element");
    video.volume = volumeRange.value;
    this.updateVolumeIcon(video, volumeIcon);
  }

  updateVolumeIcon(video, volumeIcon) {
    const options = this.videoPlayers.find((vp) =>
      vp.videoContainer.contains(volumeIcon)
    ).videoOptions;

    if (video.volume === 0 || video.muted) {
      volumeIcon.innerHTML = options.icons.volumeOff
        ? `<img src="${options.icons.volumeOff}" alt="Volume Off" />`
        : this.getDefaultIcon("volumeOff");
      volumeIcon.setAttribute("aria-label", "Unmute");
    } else {
      volumeIcon.innerHTML = options.icons.volumeOn
        ? `<img src="${options.icons.volumeOn}" alt="Volume On" />`
        : this.getDefaultIcon("volumeOn");
      volumeIcon.setAttribute("aria-label", "Mute");
    }
  }

  toggleMute(volumeRange, volumeIcon) {
    const video = volumeRange
      .closest(".ob-video-wrapper")
      .querySelector(".ob-video-element");
    video.muted = !video.muted;
    if (video.muted) {
      volumeRange.value = 0;
    } else {
      volumeRange.value = video.volume;
    }
    this.updateVolumeIcon(video, volumeIcon);
  }

  toggleFullscreen(button) {
    const videoContainer = button.closest(".ob-video-wrapper");
    const options = this.videoPlayers.find(
      (vp) => vp.videoContainer === videoContainer
    ).videoOptions;

    if (!document.fullscreenElement) {
      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen();
      } else if (videoContainer.webkitRequestFullscreen) {
        /* Safari */
        videoContainer.webkitRequestFullscreen();
      } else if (videoContainer.msRequestFullscreen) {
        /* IE11 */
        videoContainer.msRequestFullscreen();
      }
      button.setAttribute("aria-label", "Exit Fullscreen");
      button.innerHTML = options.icons.fullscreenExit
        ? `<img src="${options.icons.fullscreenExit}" alt="Exit Fullscreen" />`
        : this.getDefaultIcon("fullscreenExit");
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
      button.setAttribute("aria-label", "Enter Fullscreen");
      button.innerHTML = options.icons.fullscreen
        ? `<img src="${options.icons.fullscreen}" alt="Fullscreen" />`
        : this.getDefaultIcon("fullscreen");
    }
  }

  // Added destroy method
  destroy() {
    this.videoPlayers.forEach((vp) => {
      const { videoContainer, eventListeners } = vp;
      const video = videoContainer.querySelector(".ob-video-element");
      const progressBar = videoContainer.querySelector(".ob-progress-bar");
      const volumeRange = videoContainer.querySelector(".ob-volume-range");
      const videoContainerElement = videoContainer;

      // Remove video event listeners
      eventListeners.video.forEach((listener) => {
        video.removeEventListener(listener.type, listener.handler);
      });

      // Remove progressBar event listeners
      if (progressBar && eventListeners.progressBar) {
        eventListeners.progressBar.forEach((listener) => {
          progressBar.removeEventListener(listener.type, listener.handler);
        });
      }

      // Remove volumeRange event listeners
      if (volumeRange && eventListeners.volumeRange) {
        eventListeners.volumeRange.forEach((listener) => {
          volumeRange.removeEventListener(listener.type, listener.handler);
        });
      }

      // Remove videoContainer event listeners
      eventListeners.videoContainer.forEach((listener) => {
        videoContainerElement.removeEventListener(
          listener.type,
          listener.handler
        );
      });

      // Remove document event listeners
      eventListeners.document.forEach((listener) => {
        document.removeEventListener(listener.type, listener.handler);
      });

      // Disconnect IntersectionObserver
      if (eventListeners.observer) {
        eventListeners.observer.disconnect();
      }

      // Optionally, remove the videoContainer from the DOM
      // vp.parent.removeChild(videoContainer);
    });

    // Clear the videoPlayers array
    this.videoPlayers = [];
  }
}

let obVideoPlayer;
let videoTrigger,
  modal,
  video,
  media,
  closeModals,
  underlay,
  videoContainer,
  playOverlay;

window.isVideoModalMounted = false;

function initVideoModal() {
  // Clean up any existing instance first
  destroyVideoModal();

  videoTrigger = document.querySelector("[data-mission-video]");
  modal = document.querySelector('[data-video-modal="wrap"]');
  video = modal?.querySelector('[data-video-modal="video"]');
  media = video?.querySelector('[data-video-modal="media"]');
  closeModals = modal?.querySelectorAll('[data-video-modal="close"]');
  underlay = modal?.querySelector(".video__modal--ul");

  if (!videoTrigger || !modal || !video || !media || !underlay) {
    console.error("Required elements not found");
    return;
  }

  videoContainer = media.querySelector(".video-container");
  if (!videoContainer) {
    console.error("Video container not found");
    return;
  }

  // Initialize OBVideoPlayer with options
  const options = {
    controls: {
      playButton: true,
      volumeControl: true,
      progressBar: true,
      fullscreenToggle: true,
    },
    icons: {
      // You can set default icon URLs here if desired
    },
    colors: {
      // You can set default colors here if desired
    },
  };
  obVideoPlayer = new OBVideoPlayer(options);

  // Get the overlay after OBVideoPlayer has initialized
  playOverlay = videoContainer.querySelector(".ob-overlay-container");

  if (!playOverlay) {
    console.error("Play overlay not found");
    return;
  }

  // Set initial states
  gsap.set(underlay, { autoAlpha: 0 });
  gsap.set(video, { autoAlpha: 0, y: "5rem" });
  modal.style.display = "none"; // Ensure modal is initially hidden

  // Add event listeners
  videoTrigger.addEventListener("click", openModal);
  closeModals.forEach((closeModalElement) => {
    closeModalElement.addEventListener("click", closeModal);
  });

  // Optionally, add keyboard accessibility
  document.addEventListener("keydown", handleKeydown);

  window.isVideoModalMounted = true;
}

function openModal() {
  window.SScroll.stop();
  modal.style.display = "flex"; // Set modal to display flex before animating
  gsap.to(underlay, { autoAlpha: 0.9, duration: 0.6, ease: "power2.inOut" });
  gsap.to(video, {
    delay: 0.2,
    autoAlpha: 1,
    y: 0,
    duration: 0.6,
    ease: "power2.inOut",
    onComplete: () => {
      playOverlay.click(); // Start playing the video
    },
  });
}

function closeModal() {
  const videoElement = videoContainer.querySelector(".ob-video-element");
  if (videoElement) {
    videoElement.pause();
  }
  if (playOverlay) {
    playOverlay.style.display = "flex";
  }

  gsap.to(video, {
    autoAlpha: 0,
    y: "5rem",
    duration: 0.6,
    ease: "power2.inOut",
    overwrite: true,
  });
  gsap.to(underlay, {
    delay: 0.2,
    autoAlpha: 0,
    duration: 0.6,
    ease: "power2.inOut",
    overwrite: true,
    onComplete: () => {
      window.SScroll.start();
      modal.style.display = "none"; // Hide modal after animation completes
    },
  });
}

function handleKeydown(e) {
  if (e.key === "Escape" && modal.style.display !== "none") {
    closeModal();
  }
}

function destroyVideoModal() {
  if (!modal) return; // If modal doesn't exist, there's nothing to destroy

  // Remove event listeners
  if (videoTrigger) videoTrigger.removeEventListener("click", openModal);
  if (closeModals) {
    closeModals.forEach((closeModalElement) => {
      closeModalElement.removeEventListener("click", closeModal);
    });
  }
  document.removeEventListener("keydown", handleKeydown);

  // Destroy the OBVideoPlayer instance
  if (obVideoPlayer) {
    obVideoPlayer.destroy();
  }

  // Reset GSAP animations
  gsap.killTweensOf(underlay);
  gsap.killTweensOf(video);

  // Reset elements to their initial state
  if (underlay) gsap.set(underlay, { clearProps: "all" });
  if (video) gsap.set(video, { clearProps: "all" });

  // Ensure modal is hidden when destroyed
  modal.style.display = "none";

  // Clear references
  obVideoPlayer = null;
  videoTrigger = null;
  modal = null;
  video = null;
  media = null;
  closeModals = null;
  underlay = null;
  videoContainer = null;
  playOverlay = null;

  console.log("Video modal destroyed");
}

// Function to call when the page loads or when navigating to the page with the video modal
function mountVideoModal() {
  initVideoModal();
}

// Function to call when navigating away from the page with the video modal
function unmountVideoModal() {
  destroyVideoModal();
}

// Export these functions to be used in your SPA routing logic
export { mountVideoModal, unmountVideoModal };
