import { gsap } from "gsap";
import { ecosystem } from "../../modules/rive";

// ******
// -------------------------------- ECOSYSTEM SECTION
// ******

window.ecoTabs = null;

function initEcoTabs() {
  const tabGroup = document.querySelector('[eco-tab="wrap"]');
  const tabs = tabGroup.querySelectorAll("[eco-tab-id]");
  const ease = "cubic-bezier(.38,.005,.215,1)";
  const durationIn = 0.6;
  const durationOut = 0.3;

  // Initial states
  tabs.forEach((tab) => {
    const line = tab.querySelector(".eco__tab--line");
    const underlay = tab.querySelector('[eco-tab="ul"]');
    const reveal = tab.querySelector('[eco-tab="reveal"]');

    gsap.set(tab, { color: "rgba(0, 0, 0, 1)" });
    gsap.set(line, { scaleX: 1 });
    gsap.set(underlay, { x: "-101%" });
    gsap.set(reveal, { height: 0 });
  });

  // Set the first tab as active initially
  activateTab(tabs[0]);

  function activateTab(tabToActivate) {
    if (tabToActivate.classList.contains("active")) return;

    const index = Array.from(tabs).indexOf(tabToActivate);
    const line = tabToActivate.querySelector(".eco__tab--line");
    const underlay = tabToActivate.querySelector('[eco-tab="ul"]');
    const reveal = tabToActivate.querySelector('[eco-tab="reveal"]');

    // Reset all tabs to initial state
    tabs.forEach((otherTab) => {
      if (otherTab !== tabToActivate) {
        const otherLine = otherTab.querySelector(".eco__tab--line");
        const otherUnderlay = otherTab.querySelector('[eco-tab="ul"]');
        const otherReveal = otherTab.querySelector('[eco-tab="reveal"]');

        gsap.to(otherTab, {
          color: "rgba(0, 0, 0, 1)",
          ease: ease,
          duration: durationOut,
        });
        gsap.to(otherLine, {
          scaleX: 1,
          ease: ease,
          duration: durationOut,
        });
        gsap.to(otherUnderlay, {
          x: "-101%",
          ease: ease,
          duration: durationOut,
        });
        gsap.to(otherReveal, {
          height: 0,
          ease: ease,
          duration: durationOut,
        });

        otherTab.classList.remove("active");
      }
    });

    // Animate the clicked tab to the active state
    gsap.to(tabToActivate, {
      color: "rgba(245, 245, 247, 1)",
      ease: ease,
      duration: durationIn,
    });
    gsap.to(line, { scaleX: 0, ease: ease, duration: durationIn });
    gsap.to(underlay, { x: "0%", ease: ease, duration: durationIn });
    gsap.to(reveal, {
      height: "auto",
      ease: ease,
      duration: durationIn,
    });

    // Animate the line of the following tab, if it exists
    if (index < tabs.length - 1) {
      const nextTab = tabs[index + 1];
      const nextLine = nextTab.querySelector(".eco__tab--line");
      gsap.to(nextLine, { scaleX: 0, ease: ease, duration: durationIn });
    }

    // Mark the clicked tab as active
    tabToActivate.classList.add("active");
  }

  tabs.forEach((tab) => {
    tab.addEventListener("click", () => {
      activateTab(tab);
    });
  });

  // New function to activate a tab by index
  function activateTabByIndex(index) {
    if (index >= 0 && index < tabs.length) {
      activateTab(tabs[index]);
    } else {
      // console.warn(`Tab with index ${index} not found.`);
    }
  }

  // Return the activateTabByIndex function so it can be used externally
  return {
    activateTabByIndex: activateTabByIndex,
  };
}

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initEcosystem() {
  return ecosystem().then(({ triggerRiveEvent: triggerEvent }) => {
    triggerRiveEvent = triggerEvent;
    window.ecoTabs = initEcoTabs();
    return destroyEcosystem;
  });
}

export function destroyEcosystem() {
  // Kill all GSAP animations
  // gsap.killTweensOf("*");

  // Reset all tab elements
  const tabGroup = document.querySelector('[eco-tab="wrap"]');
  if (tabGroup) {
    const tabs = tabGroup.querySelectorAll("[eco-tab-id]");

    tabs.forEach((tab) => {
      const line = tab.querySelector(".eco__tab--line");
      const underlay = tab.querySelector('[eco-tab="ul"]');
      const reveal = tab.querySelector('[eco-tab="reveal"]');

      // Reset styles
      gsap.set(tab, { clearProps: "all" });
      gsap.set(line, { clearProps: "all" });
      gsap.set(underlay, { clearProps: "all" });
      gsap.set(reveal, { clearProps: "all" });

      // Remove active class
      tab.classList.remove("active");

      // Remove event listeners
      tab.replaceWith(tab.cloneNode(true));
    });
  }

  // Stop and clean up Rive instance
  if (window.riveInstance) {
    window.riveInstance.stop();
    window.riveInstance.cleanup();
    window.riveInstance = null;
  }

  // Clear global variables
  window.ecoTabs = null;
  triggerRiveEvent = undefined;
}
