import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let footerScrollTrigger;

export function initFooter() {
  const parent = document.querySelector("[data-footer]");
  if (!parent) return;

  const group1 = parent.querySelectorAll('[data-fa="1"]');
  const group2 = parent.querySelectorAll('[data-fa="2"]');
  const group3 = parent.querySelectorAll('[data-fa="3"]');
  const links = parent.querySelectorAll(".footer__link--w .textlink--w");
  //   const eyebrows = parent.querySelectorAll(".h-eyebrow.footer");

  const duration = 0.6;
  const ease = "cubic-bezier(0.38,0.005,0.215,1)";
  const stagger = 0.05;

  // Set initial states
  gsap.set([group1, group2, group3, links], {
    autoAlpha: 0,
    y: "101%",
  });

  footerScrollTrigger = ScrollTrigger.create({
    // scroller: window.scrollWrap,
    trigger: parent,
    start: "top 80%",
    once: true,
    // markers: true,
    onEnter: () => {
      gsap.to(group1, {
        delay: 0,
        autoAlpha: 1,
        y: "0%",
        ease: ease,
        duration: duration,
        stagger: stagger,
      });
      gsap.to(group2, {
        delay: 0.1,
        autoAlpha: 1,
        y: "0%",
        ease: ease,
        duration: duration,
        stagger: stagger,
      });
      gsap.to(group3, {
        delay: 0.2,
        autoAlpha: 1,
        y: "0%",
        ease: ease,
        duration: duration,
        stagger: stagger,
      });
      gsap.to(links, {
        delay: 0.1,
        autoAlpha: 1,
        y: "0%",
        ease: ease,
        duration: duration,
        stagger: stagger,
      });
      //   gsap.to(eyebrows, {
      //     delay: 0,
      //     autoAlpha: 1,
      //     y: "0%",
      //     ease: ease,
      //     duration: duration,
      //     stagger: stagger,
      //   });
    },
  });
}

export function destroyFooter() {
  if (footerScrollTrigger) {
    footerScrollTrigger.kill();
    footerScrollTrigger = null;
  }

  const parent = document.querySelector("[data-footer]");
  if (!parent) return;

  const allElements = parent.querySelectorAll(
    '[data-fa="1"], [data-fa="2"], [data-fa="3"], .footer__link--w .textlink--w'
  );

  // Reset all animated elements to their original state
  gsap.set(allElements, {
    clearProps: "all",
  });
}

// Function to handle dropdown accessibility and visibility
function setupDropdownAccessibility() {
  const dropdowns = document.querySelectorAll(".dropdown--w");

  dropdowns.forEach((dropdown) => {
    const trigger = dropdown.querySelector(".textlink--w");
    const content = dropdown.querySelector(".dropdown--c");

    // Set initial state
    dropdown.setAttribute("aria-expanded", "false");
    // content.style.display = "none";

    // Function to show dropdown
    function showDropdown() {
      dropdown.setAttribute("aria-expanded", "true");
      //   content.style.display = "block";
    }

    // Function to hide dropdown
    function hideDropdown() {
      dropdown.setAttribute("aria-expanded", "false");
      //   content.style.display = "none";
    }

    // Event listeners
    dropdown.addEventListener("mouseover", showDropdown);
    dropdown.addEventListener("mouseleave", hideDropdown);

    // Keyboard accessibility
    dropdown.addEventListener("focus", showDropdown);
    trigger.addEventListener("blur", (event) => {
      // Delay hiding to allow focus to move to dropdown content
      setTimeout(() => {
        if (!dropdown.contains(document.activeElement)) {
          hideDropdown();
        }
      }, 0);
    });
  });
}

export function initHexTest() {
  const trigger = document.querySelector('[data-mm="trigger"]');
  // removing hex functionality
  // const hexElements = document.querySelectorAll('[data-mm="hex"]');
  const underlay = document.querySelectorAll('[data-mm="underlay"]');
  const navLinks = document.querySelectorAll(".nav__links--outer");
  const dropdownLinks = document.querySelectorAll(
    ".nav__links--outer .dropdown--link"
  );
  const linkHeadings = document.querySelectorAll(
    ".nav__links--outer .btn-inner__text--w"
  );

  // console.log("script updated 08");

  const baseStagger = 0.1;
  const duration = 0.5;
  if (!trigger === 0) return;

  let isMenuOpen = false;

  function openMenu() {
    // Stop scroll before opening menu
    if (window.SScroll && typeof window.SScroll.stop === "function") {
      window.SScroll.stop();
      // console.log("Scroll stopped mobile");
    } else {
      console.error("SScroll.stop is not available");
    }

    // Open menu animations...
    // gsap.to(hexElements, {
    //   scale: 1,
    //   duration: duration,
    //   rotate: 120,
    //   ease: "none",
    //   stagger: {
    //     amount: baseStagger * (hexElements.length - 1),
    //     from: "start",
    //   },
    //   overwrite: true,
    // });

    gsap.to(navLinks, {
      delay: 0.3,
      x: "0%",
      duration: duration,
      ease: "power2.out",
      overwrite: true,
    });

    gsap.to(underlay, {
      delay: 0,
      x: "0%",
      duration: duration,
      ease: "power2.out",
      overwrite: true,
    });

    gsap.fromTo(
      dropdownLinks,
      { y: "101%", autoAlpha: 0 },
      {
        y: "0%",
        autoAlpha: 1,
        duration: duration,
        stagger: 0.05,
        ease: "power2.out",
        delay: 0.1 + duration,
        overwrite: true,
      }
    );

    gsap.fromTo(
      linkHeadings,
      { y: "101%", autoAlpha: 0 },
      {
        y: "0%",
        autoAlpha: 1,
        duration: duration,
        stagger: 0.05,
        ease: "power2.out",
        delay: 0.1 + duration,
        overwrite: true,
      }
    );

    // Add .is--open class to trigger
    trigger.classList.add("is--open");

    isMenuOpen = true;
  }

  function closeMenu() {
    // Close menu animations...
    // gsap.to(hexElements, {
    //   scale: 0,
    //   duration: duration,
    //   rotate: 0,
    //   ease: "none",
    //   stagger: {
    //     amount: baseStagger * (hexElements.length - 1),
    //     from: "end",
    //   },
    //   overwrite: true,
    // });

    gsap.to(navLinks, {
      x: "-101%",
      duration: duration,
      stagger: baseStagger,
      ease: "power2.in",
      overwrite: true,
    });
    gsap.to(underlay, {
      delay: 0,
      x: "101%",
      duration: duration,
      ease: "power2.out",
      overwrite: true,
    });

    gsap.to([dropdownLinks, linkHeadings], {
      y: "101%",
      autoAlpha: 0,
      duration: 0.2,
      overwrite: true,
    });

    // --------------- SCROLL CONTROL
    gsap.delayedCall(duration, () => {
      if (window.SScroll && typeof window.SScroll.start === "function") {
        window.SScroll.start();
      } else {
        console.error("SScroll.start is not available");
      }
    });

    // Remove .is--open class from trigger
    trigger.classList.remove("is--open");

    isMenuOpen = false;
  }

  trigger.addEventListener("click", () => {
    if (!isMenuOpen) {
      openMenu();
    } else {
      closeMenu();
    }
  });

  // Add click event listeners to all dropdown links
  dropdownLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      if (isMenuOpen) {
        e.preventDefault(); // Prevent default link behavior
        closeMenu();
      }
    });
  });
}
// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initNav() {
  if (!window.isMob) {
    setupDropdownAccessibility();
  } else {
    initHexTest();
  }
  // init
}

export function destroyNav() {
  // destroy
}
