import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function aboutSectionAnims() {
  const section1 = document.querySelector('[data-s-group="1"]');
  const section2bg = document.querySelector('[data-s-group="2"]');
  const s2text = document.querySelectorAll('[data-s-hl="2"] .char');

  if (!section1 || !section2bg) {
    // console.error("One or more sections not found");
    return;
  }

  // Set initial states
  gsap.set(s2text, { autoAlpha: 0, y: "101%" });
  gsap.set([section1, section2bg], { scale: 0.95 });

  // Create the ScrollTrigger for section1
  ScrollTrigger.create({
    trigger: section1,
    start: "top bottom",
    end: "top 50%",
    scrub: true,
    once: true,
    animation: gsap.to(section1, {
      scale: 1,
      y: "0%",
      ease: "none", // Linear ease for smooth scrubbing
    }),
  });

  // Create the ScrollTrigger for section2bg
  ScrollTrigger.create({
    trigger: section2bg,
    start: "top bottom",
    end: "top 50%",
    scrub: true,
    once: true,
    animation: gsap.to(section2bg, {
      scale: 1,
      y: "0%",
      ease: "none", // Linear ease for smooth scrubbing
    }),
  });

  // Create the ScrollTrigger for s2text animation
  ScrollTrigger.create({
    trigger: section2bg, // Changed from section2 to section2bg
    start: "top 80%",
    once: true,
    onEnter: () => {
      // Animate chars
      gsap.to(s2text, {
        delay: 0.5,
        autoAlpha: 1,
        y: "0%",
        duration: 0.6,
        stagger: 0.05,
        ease: "power1.out",
      });
    },
  });
}

export function destroyAboutSectionAnims() {}
