import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { refreshAllScrollTriggers } from "../../modules/utils";

gsap.registerPlugin(ScrollTrigger);

// ******
// -------------------------------- FILTER
// ******

let filterClickListener;

export function initCmsFilter() {
  const script = document.createElement("script");
  script.src =
    "https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmsfilter@1/cmsfilter.js";
  script.async = true;
  script.onload = () => {
    window.fsAttributes = window.fsAttributes || [];
    window.fsAttributes.push([
      "cmsfilter",
      (filterInstances) => {
        // Filter instances initialization logic if needed
      },
    ]);
  };
  document.head.appendChild(script);
  monitorFilterClicks();
}

export function destroyCmsFilter() {
  const script = document.querySelector(
    'script[src="https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmsfilter@1/cmsfilter.js"]',
  );
  if (script) {
    document.head.removeChild(script);
  }

  if (window.fsAttributes && Array.isArray(window.fsAttributes)) {
    window.fsAttributes = window.fsAttributes.filter(
      (attr) => attr[0] !== "cmsfilter",
    );
  }

  const filtersContainer = document.querySelector(".filters__form--group");
  if (filtersContainer && filterClickListener) {
    filtersContainer.removeEventListener("click", filterClickListener);
  }

  filterClickListener = null;
  window.removeEventListener("scrollTriggersRefreshed", anyCustomHandlers);
}

function monitorFilterClicks() {
  const filtersContainer = document.querySelector(".filters__form--group");
  if (!filtersContainer) return;

  filterClickListener = function (event) {
    const clickedFilter = event.target.closest(".filters__radio--outer");
    if (clickedFilter) {
      setTimeout(refreshAllScrollTriggers, 300);
    }
  };

  filtersContainer.addEventListener("click", filterClickListener);
}

function anyCustomHandlers() {
  // Custom handler logic for scrollTriggersRefreshed event
}

// ******
// -------------------------------- LOAD
// ******

export function initCmsLoad() {
  // Dynamically load the cmsload script
  const script = document.createElement("script");
  script.src =
    "https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmsload@1/cmsload.js";
  script.async = true;
  script.onload = () => {
    // Initialize cmsload instances by pushing a callback into the window.fsAttributes object
    window.fsAttributes = window.fsAttributes || [];
    window.fsAttributes.push([
      "cmsload",
      (loadInstances) => {
        // console.log("cmsload Successfully loaded!");
        // Here, you can access the loadInstances array with all the CMSLoad instances on the page
      },
    ]);
  };
  document.head.appendChild(script);
}

export function destroyCmsLoad() {
  // Remove the script element from the document
  const script = document.querySelector(
    'script[src="https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmsload@1/cmsload.js"]',
  );
  if (script) {
    document.head.removeChild(script);
  }
  // Assuming fsAttributes is not used elsewhere, reset it
  window.fsAttributes = [];
}

// ******
// -------------------------------- SORT
// ******

export function initCmsSort() {
  // Dynamically load the cmssort script
  const script = document.createElement("script");
  script.src =
    "https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmssort@1/cmssort.js";
  script.async = true;
  script.onload = () => {
    // Initialize cmssort instances by pushing a callback into the window.fsAttributes object
    window.fsAttributes = window.fsAttributes || [];
    window.fsAttributes.push([
      "cmssort",
      (sortInstances) => {
        // console.log("cmssort Successfully loaded!");
        // Here, you can access the sortInstances array with all the CMSSort instances on the page
      },
    ]);
  };
  document.head.appendChild(script);
}

export function destroyCmsSort() {
  // Remove the script element from the document
  const script = document.querySelector(
    'script[src="https://cdn.jsdelivr.net/npm/@finsweet/attributes-cmssort@1/cmssort.js"]',
  );
  if (script) {
    document.head.removeChild(script);
  }
  // Assuming fsAttributes is not used elsewhere, reset it
  window.fsAttributes = [];
}
