import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// ******
// ---------------------------------------- SECTION IN
// ******

function missionSectionIn() {
  const section = document.querySelector('[data-s-group="1"]');
  const chars = document.querySelectorAll('[data-s-hl="1"] .char');

  if (!section) {
    console.error("Section not found");
    return;
  }

  // Set initial states
  gsap.set(chars, { autoAlpha: 0, y: "101%" });
  gsap.set(section, { scale: 0.95 });

  // Create the ScrollTrigger for the section
  ScrollTrigger.create({
    // scroller: window.scrollWrap,
    trigger: section,
    start: "top 90%",
    end: "top 50%",
    scrub: true, // This makes the animation scrub with scroll
    onEnter: () => {
      // Animate chars (this remains the same, not scrubbed)
      gsap.to(chars, {
        delay: 0.5, // Reduced delay to start sooner
        autoAlpha: 1,
        y: "0%",
        duration: 0.6,
        stagger: 0.05,
        ease: "power1.out",
      });
    },
    animation: gsap.to(section, {
      scale: 1,
      y: "0%",
      ease: "none", // Linear ease for smooth scrubbing
    }),
  });
}
// ******
// ---------------------------------------- VIDEO MASK
// ******

function missionMaskAnimate() {
  const parent = document.querySelector("[data-mission-video]");
  const container = parent.querySelector('[data-video="container"]');
  const words = parent.querySelectorAll('[data-mission="text"] .word');
  const playIcon = document.querySelector('[data-mission="play"]');
  const visa = parent.querySelector('[data-mission="visa"]');

  // Set initial states
  gsap.set(words, { autoAlpha: 1, y: "101%" });
  gsap.set(playIcon, { autoAlpha: 1, scale: 0, rotation: 360 });
  gsap.set(visa, { autoAlpha: 1, y: "101%" });

  // Create the ScrollTrigger
  ScrollTrigger.create({
    // scroller: window.scrollWrap,
    trigger: container,
    start: "top 80%",
    // markers: true,
    once: true,
    onEnter: () => {
      // Clip-path animation
      gsap.fromTo(
        container,
        {
          clipPath: "inset(50% 25% 0% 25% round 0px)",
        },
        {
          clipPath: "inset(0% 0% 0% 0% round 0px)",
          duration: 2,
          ease: "power2.out",
        }
      );
      gsap.to(words, {
        delay: 1,
        autoAlpha: 1,
        y: "0%",
        duration: 1,
        stagger: 0.1,
        ease: "power2.inOut",
      });

      // Play icon animation
      gsap.to(playIcon, {
        delay: 1,
        autoAlpha: 1,
        scale: 1,
        rotation: 0,
        duration: 2,
        ease: "power2.inOut",
      });

      // Visa animation
      gsap.to(visa, {
        delay: 1,
        autoAlpha: 1,
        y: "0%",
        duration: 1,
        ease: "power2.inOut",
      });
    },
  });
}

// ******
// ---------------------------------------- TOOLTIP
// ******

function missionToolTip() {
  const parent = document.querySelector("[data-mission-video]");
  const pill = parent.querySelector('[data-mission="pill"]');
  const pillText = parent.querySelector('[data-mission="pillText"]');
  const radiusLarge = "6em";
  const ease1 = "cubic-bezier(0.38,0.005,0.215,1)";
  const ease2 = "power2.inOut";
  const duration = 0.5;

  // Initial states
  gsap.set(pill, {
    clipPath: `inset(0% calc(100% - ${radiusLarge} * 2) 0% 0% round ${radiusLarge})`,
    autoAlpha: 0,
    xPercent: -50,
    yPercent: -50,
  });
  gsap.set(pillText, {
    x: "2.5rem",
    autoAlpha: 0,
  });

  // Create a single timeline for both reveal and hide animations
  const tl = gsap.timeline({ paused: true });
  tl.to(pill, {
    clipPath: `inset(0% 0% 0% 0% round ${radiusLarge})`,
    autoAlpha: 1,
    duration: duration,
    ease: ease1,
  }).to(
    pillText,
    {
      x: 0,
      autoAlpha: 1,
      duration: duration,
      ease: ease1,
    },
    "-=0.2"
  );

  // Function to update tooltip position
  function updateTooltipPosition(e) {
    const rect = parent.getBoundingClientRect();
    const x = e.clientX - rect.left + 50;
    const y = e.clientY - rect.top + 50;
    gsap.set(pill, {
      x: x,
      y: y,
    });
  }

  // Mouse move animation
  function moveTooltip(e) {
    updateTooltipPosition(e);
  }

  // Event listeners
  parent.addEventListener("mouseenter", (e) => {
    updateTooltipPosition(e);
    tl.play();
    parent.addEventListener("mousemove", moveTooltip);
  });

  parent.addEventListener("mouseleave", () => {
    tl.reverse();
    parent.removeEventListener("mousemove", moveTooltip);
  });

  // Handle scroll events
  window.addEventListener("scroll", () => {
    if (tl.progress() > 0) {
      const rect = parent.getBoundingClientRect();
      const x = lastMousePosition.x - rect.left + 50;
      const y = lastMousePosition.y - rect.top + 50;
      gsap.set(pill, {
        x: x,
        y: y,
      });
    }
  });

  // Keep track of the last mouse position
  let lastMousePosition = { x: 0, y: 0 };
  document.addEventListener("mousemove", (e) => {
    lastMousePosition.x = e.clientX;
    lastMousePosition.y = e.clientY;
  });
}

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initMission() {
  missionMaskAnimate();
  missionSectionIn();
  missionToolTip();
}

export function destroyMission() {
  // Kill all ScrollTriggers
  ScrollTrigger.getAll().forEach((trigger) => trigger.kill());

  // Clean up missionSectionIn
  const section = document.querySelector('[data-s-group="1"]');
  const chars = document.querySelectorAll('[data-s-hl="1"] .char');
  if (section) gsap.set(section, { clearProps: "all" });
  if (chars.length) gsap.set(chars, { clearProps: "all" });

  // Clean up missionMaskAnimate
  const parent = document.querySelector("[data-mission-video]");
  if (parent) {
    const container = parent.querySelector('[data-video="container"]');
    const words = parent.querySelectorAll('[data-mission="text"] .word');
    const playIcon = document.querySelector('[data-mission="play"]');
    const visa = parent.querySelector('[data-mission="visa"]');

    if (container) gsap.set(container, { clearProps: "all" });
    if (words.length) gsap.set(words, { clearProps: "all" });
    if (playIcon) gsap.set(playIcon, { clearProps: "all" });
    if (visa) gsap.set(visa, { clearProps: "all" });
  }

  // Clean up missionToolTip
  if (parent) {
    const pill = parent.querySelector('[data-mission="pill"]');
    const pillText = parent.querySelector('[data-mission="pillText"]');

    if (pill) gsap.set(pill, { clearProps: "all" });
    if (pillText) gsap.set(pillText, { clearProps: "all" });

    // Remove event listeners
    // parent.removeEventListener("mouseenter", handleMouseEnter);
    // parent.removeEventListener("mouseleave", handleMouseLeave);
    // parent.removeEventListener("mousemove", moveTooltip);
  }

  // // Remove scroll and mousemove event listeners
  // window.removeEventListener("scroll", handleScroll);
  // document.removeEventListener("mousemove", updateLastMousePosition);
}
