import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// ******
// -------------------------------- ROADMAP
// ******

function initRoadmapScroll() {
  // Select all segments
  const segments = document.querySelectorAll(".rm__track--sec:not(.hex)");

  segments.forEach((segment) => {
    gsap.fromTo(
      segment,
      { clipPath: "inset(0 0 100% 0)" },
      {
        clipPath: "inset(0 0 0% 0)",
        scrollTrigger: {
          // scroller: window.scrollWrap,
          trigger: segment,
          start: "top 70%",
          end: "bottom 70%",
          scrub: true,
        },
      }
    );
  });

  // Select all dots
  const dots = document.querySelectorAll(".rm__track--dot");

  dots.forEach((dot) => {
    // Animation for when the dot enters the viewport
    gsap.fromTo(
      dot,
      { scale: 0 },
      {
        scale: 1,
        duration: 0.6,
        scrollTrigger: {
          // scroller: window.scrollWrap,
          trigger: dot,
          start: "top 70%",
          onEnter: () => gsap.to(dot, { scale: 1, duration: 0.6 }),
          onLeave: () => gsap.to(dot, { scale: 0, duration: 0.6 }),
          onEnterBack: () => gsap.to(dot, { scale: 1, duration: 0.6 }),
          onLeaveBack: () => gsap.to(dot, { scale: 0, duration: 0.6 }),
        },
      }
    );
  });

  // Select all rows
  const rows = document.querySelectorAll(".mr__track--row");

  rows.forEach((row) => {
    ScrollTrigger.create({
      // scroller: window.scrollWrap,
      trigger: row,
      start: "top 70%",
      end: "bottom 70%",
      onEnter: () => row.classList.add("active"),
      //   onLeave: () => row.classList.remove("active"),
      // onEnterBack: () => row.classList.add("active"),
      onLeaveBack: () => row.classList.remove("active"),
    });
  });
}

function adjustStrokeWidth() {
  const paths = document.querySelectorAll(".rm__track--sec path");

  paths.forEach((path) => {
    if (window.isTabletOrBelow) {
      path.setAttribute("stroke-width", "2");
    } else {
      path.setAttribute("stroke-width", "3");
    }
  });
}

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initRoadmap() {
  initRoadmapScroll();
  adjustStrokeWidth();
  tabletMediaQuery.addEventListener("change", adjustStrokeWidth);
}

export function destroyRoadmap() {
  // Kill all ScrollTrigger instances
  ScrollTrigger.getAll().forEach((trigger) => trigger.kill());

  // Select all elements that had animations
  const segments = document.querySelectorAll(".rm__track--sec:not(.hex)");
  const dots = document.querySelectorAll(".rm__track--dot");
  const rows = document.querySelectorAll(".mr__track--row");

  // Remove GSAP animations and reset styles
  [segments, dots].forEach((elements) => {
    elements.forEach((element) => {
      gsap.killTweensOf(element);
      gsap.set(element, { clearProps: "all" });
    });
  });

  // Remove active class from rows
  rows.forEach((row) => row.classList.remove("active"));

  // Clear any remaining GSAP-related inline styles
  gsap.set([...segments, ...dots, ...rows], { clearProps: "all" });

  // Remove the event listener for adjustStrokeWidth
  tabletMediaQuery.removeEventListener("change", adjustStrokeWidth);
}
