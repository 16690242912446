import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// ******
// -------------------------------- PROVIDER STATS CARDS ORIGINAL
// ******

function initPsCardsOriginal() {
  const container = document.querySelector(".ps__slider--w");
  const slider = document.querySelector(".ps__slider--track");
  const slides = Array.from(document.querySelectorAll(".ps__slider--slide"));
  const totalSlides = slides.length;
  const slideWidth = slides[0].offsetWidth;
  const containerWidth = container.offsetWidth;
  const gapBetweenSlides = 32; // 2em = 32px (assuming 1em = 16px)
  const activeThreshold = 32; // Distance from left edge to apply active class

  let dragStartX;
  let slidePositions = slides.map(
    (_, index) => index * (slideWidth + gapBetweenSlides)
  );

  function updateSlides() {
    slides.forEach((slide, index) => {
      const slideX = slidePositions[index];
      const isActive = slideX <= activeThreshold;

      if (isActive) {
        gsap.to(slide, {
          zIndex: totalSlides + 1,
          duration: 0.5,
          ease: "power2.out",
        });
        slide.classList.add("active");
      } else {
        gsap.to(slide, {
          zIndex: totalSlides + index,
          duration: 0.5,
          ease: "power2.out",
        });
        slide.classList.remove("active");
      }

      if (slideX <= 0) {
        const stackPosition = Math.max(
          0,
          Math.floor(-slideX / (slideWidth + gapBetweenSlides))
        );
        const offset = stackPosition * 0.2; // 0.2rem offset per stacked card
        gsap.to(slide, {
          x: offset + "rem",
          y: -stackPosition * 1 + "px",
          duration: 0.5,
          ease: "power2.out",
        });
      } else {
        gsap.to(slide, {
          x: slideX + "px",
          y: 0,
          duration: 0.5,
          ease: "power2.out",
        });
      }
    });
  }

  function onDragStart(e) {
    dragStartX = e.clientX || (e.touches && e.touches[0].clientX);
    document.addEventListener("mousemove", onDragMove);
    document.addEventListener("touchmove", onDragMove);
    document.addEventListener("mouseup", onDragEnd);
    document.addEventListener("touchend", onDragEnd);
  }

  function onDragMove(e) {
    const currentX = e.clientX || (e.touches && e.touches[0].clientX);
    const deltaX = currentX - dragStartX;

    const movedSlides = slidePositions.filter(
      (pos) => pos < (totalSlides - 1) * (slideWidth + gapBetweenSlides)
    );
    const lastMovedIndex = movedSlides.length;

    slidePositions = slidePositions.map((pos, index) => {
      if (index < lastMovedIndex || (deltaX < 0 && index === lastMovedIndex)) {
        return Math.max(
          0,
          Math.min(pos + deltaX, index * (slideWidth + gapBetweenSlides))
        );
      }
      return pos;
    });

    updateSlides();
    dragStartX = currentX;
  }

  function onDragEnd() {
    document.removeEventListener("mousemove", onDragMove);
    document.removeEventListener("touchmove", onDragMove);
    document.removeEventListener("mouseup", onDragEnd);
    document.removeEventListener("touchend", onDragEnd);
  }

  slider.addEventListener("mousedown", onDragStart);
  slider.addEventListener("touchstart", onDragStart);

  // Initial call to set up slides
  updateSlides();

  // Update on window resize
  window.addEventListener("resize", () => {
    const newSlideWidth = slides[0].offsetWidth;
    slidePositions = slides.map(
      (_, index) => index * (newSlideWidth + gapBetweenSlides)
    );
    updateSlides();
  });
}

// ******
// -------------------------------- PROVIDER STATS CARDS New
// ******

function providerStatsCards() {
  const stickyTrack = document.querySelector('[ps-anim="track"]');
  const container = document.querySelector(".ps__slider--w");
  const slider = document.querySelector(".ps__slider--track");
  const slides = Array.from(document.querySelectorAll(".ps__slider--slide"));
  const totalSlides = slides.length;
  const slideWidth = slides[0].offsetWidth;
  const containerWidth = container.offsetWidth;
  const gapBetweenSlides = 32; // 2em = 32px (assuming 1em = 16px)
  const activeThreshold = 32; // Distance from left edge to apply active class
  let slidePositions = slides.map(
    (_, index) => index * (slideWidth + gapBetweenSlides)
  );

  // New functionality: store original number values and set initial content to "00"
  const amounts = document.querySelectorAll(".ps__stat--num");
  const originalValues = Array.from(amounts).map((amount) =>
    parseInt(amount.textContent, 10)
  );
  amounts.forEach((amount) => (amount.textContent = "00"));

  function initializeCardPositions() {
    slides.forEach((slide, index) => {
      gsap.set(slide, {
        zIndex: (index + 1) * 10,
        scale: 1,
        autoAlpha: 1,
        x: slidePositions[index] + "px",
        y: 0,
      });
    });
    gsap.set(slider, {
      width:
        totalSlides * (slideWidth + gapBetweenSlides) - gapBetweenSlides + "px",
    });
  }

  function animateCardsIn() {
    gsap.from(slides, {
      y: "10rem",
      autoAlpha: 0,
      stagger: {
        each: 0.1,
        from: "end",
      },
      ease: "cubic-bezier(0.38,0.005,0.215,1)",
      onComplete: () => {
        //set first slide to active
        slides[0].classList.add("active");

        // Animate the numbers counting up
        amounts.forEach((amount, index) => {
          gsap.to(amount, {
            textContent: originalValues[index],
            duration: 2,
            ease: "power1.inOut",
            snap: { textContent: 1 },
            onUpdate: function () {
              amount.textContent = Math.round(this.targets()[0].textContent)
                .toString()
                .padStart(2, "0");
            },
          });
        });
      },
      scrollTrigger: {
        // scroller: window.scrollWrap,
        trigger: container,
        start: "top 80%",
        toggleActions: "play none none none",
      },
    });
  }
  function updateSlides(progress) {
    const totalMove = (totalSlides - 1) * (slideWidth + gapBetweenSlides);
    const currentMove = totalMove * progress;
    slidePositions = slidePositions.map((pos, index) =>
      Math.max(0, index * (slideWidth + gapBetweenSlides) - currentMove)
    );
    let activeIndex = -1;
    const transitionStartThreshold = activeThreshold + slideWidth * 0.2; // Adjust this value to fine-tune the start point
    slides.forEach((slide, index) => {
      const slideX = slidePositions[index];
      const isActive = slideX <= activeThreshold;
      const nextSlideX =
        index < totalSlides - 1 ? slidePositions[index + 1] : -Infinity;
      if (isActive) {
        activeIndex = index;
        slide.classList.add("active");
      } else {
        slide.classList.remove("active");
      }
      const transitionProgress =
        isActive && index < totalSlides - 1
          ? Math.max(
              0,
              Math.min(
                1,
                (transitionStartThreshold - nextSlideX) /
                  (transitionStartThreshold - activeThreshold)
              )
            )
          : 0;
      gsap.to(slide, {
        scale: isActive ? 1 - 0.2 * transitionProgress : 1,
        autoAlpha: isActive ? 1 - transitionProgress : 1,
        duration: 0.5,
        ease: "power2.out",
      });
      if (slideX <= 0) {
        const stackPosition = Math.max(
          0,
          Math.floor(-slideX / (slideWidth + gapBetweenSlides))
        );
        const offset = stackPosition * 0.2; // 0.2rem offset per stacked card
        gsap.to(slide, {
          x: offset + "rem",
          y: -stackPosition * 1 + "px",
          duration: 0.5,
          ease: "power2.out",
        });
      } else {
        gsap.to(slide, {
          x: slideX + "px",
          y: 0,
          duration: 0.5,
          ease: "power2.out",
        });
      }
    });
  }
  // set cards in container
  initializeCardPositions();

  //animate cards in
  animateCardsIn();

  //update slides
  ScrollTrigger.create({
    // scroller: window.scrollWrap,
    trigger: stickyTrack,
    start: "top top",
    end: "bottom bottom",
    scrub: 1,
    onUpdate: (self) => {
      updateSlides(self.progress);
    },
  });

  window.addEventListener("resize", () => {
    const newSlideWidth = slides[0].offsetWidth;
    slidePositions = slides.map(
      (_, index) => index * (newSlideWidth + gapBetweenSlides)
    );
    initializeCardPositions();
    updateSlides(ScrollTrigger.getById(stickyTrack).progress);
  });
}

function activateCard(card, index) {
  card.classList.add("active");
  gsap.to(card, {
    scale: 0.95,
    zIndex: 10 + index,
    duration: 0.3,
  });
}

function deactivateCard(card) {
  card.classList.remove("active");
  gsap.to(card, {
    scale: 1,
    zIndex: (i) => cards.length - i,
    duration: 0.3,
  });
}
// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initProviderStats() {
  // initPsCardsOriginal();
  providerStatsCards();
}

export function destroyProviderStats() {
  // Remove ScrollTrigger
  const stickyTrack = document.querySelector('[ps-anim="track"]');
  if (stickyTrack) {
    const scrollTrigger = ScrollTrigger.getById(stickyTrack);
    if (scrollTrigger) {
      scrollTrigger.kill();
    }
  }

  // Remove GSAP animations
  const slides = Array.from(document.querySelectorAll(".ps__slider--slide"));
  slides.forEach((slide) => {
    gsap.killTweensOf(slide);
  });

  // Remove event listeners
  window.removeEventListener("resize", updateOnResize);

  // Reset styles and classes
  const slider = document.querySelector(".ps__slider--track");
  if (slider) {
    gsap.set(slider, { clearProps: "width" });
  }

  slides.forEach((slide) => {
    slide.classList.remove("active");
    gsap.set(slide, { clearProps: "all" });
  });

  // Clear any remaining GSAP-related inline styles
  gsap.set([slider, ...slides], { clearProps: "all" });
}

// Helper function to update on resize (needs to be defined separately to be able to remove the event listener)
function updateOnResize() {
  const slides = Array.from(document.querySelectorAll(".ps__slider--slide"));
  const slideWidth = slides[0].offsetWidth;
  const gapBetweenSlides = 32;
  const slidePositions = slides.map(
    (_, index) => index * (slideWidth + gapBetweenSlides)
  );

  // Reinitialize card positions
  slides.forEach((slide, index) => {
    gsap.set(slide, {
      x: slidePositions[index] + "px",
      y: 0,
    });
  });

  // Update slider width
  const slider = document.querySelector(".ps__slider--track");
  if (slider) {
    gsap.set(slider, {
      width:
        slides.length * (slideWidth + gapBetweenSlides) -
        gapBetweenSlides +
        "px",
    });
  }

  // Trigger ScrollTrigger update
  ScrollTrigger.update();
}
