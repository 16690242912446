let initializedWorld;
window.worldIsLoaded = false;

async function initializeWorld() {
  const worldModule = await import(
    "https://assets.itsoffbrand.io/uplink/webgl/world.4.js"
  );
  window.world = worldModule.world;

  if (!world || typeof world.init !== "function") {
    throw new Error("World object is not properly defined");
  }

  const canvas = document.querySelector("canvas.gl");

  try {
    await world.init(
      canvas,
      "https://assets.itsoffbrand.io/uplink/webgl/assets/",
      onLoad,
      onProgress,
      onIntroFinished
    );
    initializedWorld = world;
    return world;
  } catch (error) {
    console.error("Error initializing world:", error);
    throw error;
  }
}

export async function initWorld() {
  try {
    const world = await initializeWorld();

    // You can now use the initialized world object here
    // For example:
    // world.someOtherMethod();
  } catch (error) {
    console.error("Failed to initialize the world:", error);
  }
}

async function onLoad() {
  // console.log("onLoad");

  // Wait for playIntro method to be available, worldIsLoaded, and riveAssetsLoaded to be true
  const checkConditions = () => {
    if (
      typeof initializedWorld.playIntro === "function" &&
      window.worldIsLoaded &&
      window.riveAssetsLoaded
    ) {
      setTimeout(() => {
        initializedWorld.playIntro();
      }, 500);
    } else {
      // If conditions are not met, check again after a short delay
      setTimeout(checkConditions, 100);
    }
  };

  // Start checking conditions
  checkConditions();
}

function onProgress(loadProgress) {
  // console.log("onProgress, loadProgress: " + loadProgress);
  // if progress = 1 set window.worldIsLoaded = true
  if (loadProgress === 1) {
    window.worldIsLoaded = true;
    // console.log("worldIsLoaded: ", window.worldIsLoaded);
  }
}

function onIntroFinished() {
  // console.log("onIntroFinished");
  if (window.SScroll && typeof window.SScroll.start === "function") {
    window.SScroll.start();
    // console.log('Scroll start - world');
  } else {
    console.error("SScroll.start is not available");
  }
}

function cyclePictures() {
  // world.cyclePictures(0);
  let num = 0;
  setInterval(() => {
    num++;
    // console.log(num % 3);
    initializedWorld.cyclePictures(num % 3);
  }, 4500);
}

function setupCallbacks() {
  initializedWorld.addBluetoothCallbacks(onBluetoothClick);
  initializedWorld.addCityCallbacks(onCityClick, onCityOver, onCityOut);
}

function onBluetoothClick(data) {
  // console.log("onBluetoothClick", data);
}

function onCityClick(data) {
  // console.log("onCityClick", data);
}

function onCityOver(data) {
  // console.log("onCityOver", data);
}

function onCityOut(data) {
  // console.log("onCityOut", data);
}

export { initializeWorld, setupCallbacks };
