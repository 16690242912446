import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ease1 = "cubic-bezier(0.38,0.005,0.215,1)";

export function featuredMaskAnimate() {
  const parent = document.querySelector('[data-featured="parent"]');
  const mask = parent?.querySelector('[data-featured="mask"]');
  const words = parent?.querySelectorAll('[data-featured="text"] .word');
  const para = parent?.querySelectorAll('[data-featured="para"] .word');
  const tag = parent?.querySelector('[data-featured="tag"]');
  const button = parent?.querySelector('[data-featured="button"]');

  // Set initial states
  gsap.set(words, { autoAlpha: 0, y: "101%" });
  gsap.set(para, { autoAlpha: 0, y: "101%" });
  gsap.set(tag, { autoAlpha: 0, y: "101%" });
  gsap.set(button, { autoAlpha: 0, y: "101%" });

  // Create the ScrollTrigger
  const trigger = ScrollTrigger.create({
    trigger: mask,
    start: "top 80%",
    // markers: true,
    once: true,
    onEnter: () => {
      // Clip-path animation
      gsap.fromTo(
        mask,
        {
          clipPath: "inset(50% 25% 0% 25% round 0px)",
        },
        {
          clipPath: "inset(0% 0% 0% 0% round 0px)",
          duration: 2,
          ease: "power2.inOut",
        },
      );
      gsap.to(words, {
        delay: 1,
        autoAlpha: 1,
        y: "0%",
        duration: 1,
        stagger: 0.1,
        ease: "power2.inOut",
      });
      gsap.to(para, {
        delay: 1.1,
        autoAlpha: 1,
        y: "0%",
        duration: 1,
        stagger: 0.01,
        ease: ease1,
      });
      gsap.to(tag, {
        delay: 1,
        autoAlpha: 1,
        y: "0%",
        duration: 1,
        ease: "power2.inOut",
      });
      gsap.to(button, {
        delay: 1.3,
        autoAlpha: 1,
        y: "0%",
        duration: 1,
        ease: "power2.inOut",
      });
    },
  });

  // Store the ScrollTrigger instance on the parent element
  parent.scrollTriggerInstance = trigger;
}

export function destroyFeaturedMask() {
  const parent = document.querySelector('[data-featured="parent"]');
  const mask = parent?.querySelector('[data-featured="mask"]');
  const words = parent?.querySelectorAll('[data-featured="text"] .word');
  const para = parent?.querySelectorAll('[data-featured="para"] .word');
  const tag = parent?.querySelector('[data-featured="tag"]');
  const button = parent?.querySelector('[data-featured="button"]');

  // Kill the ScrollTrigger instance
  if (parent.scrollTriggerInstance) {
    parent.scrollTriggerInstance.kill();
    delete parent.scrollTriggerInstance;
  }

  // Kill all GSAP animations and reset styles
  gsap.killTweensOf([mask, words, para, tag, button]);

  // // Reset elements to their initial state
  // gsap.set(mask, { clipPath: "inset(50% 25% 0% 25% round 0px)" });
  // gsap.set([words, para, tag, button], { clearProps: "all" });

  // // Re-apply initial states
  // gsap.set(words, { autoAlpha: 0, y: "101%" });
  // gsap.set(para, { autoAlpha: 0, y: "101%" });
  // gsap.set(tag, { autoAlpha: 0, y: "101%" });
  // gsap.set(button, { autoAlpha: 0, y: "101%" });
}
