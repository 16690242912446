import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function initHlAnims() {
  const parents = document.querySelectorAll('[hl-anim="parent"]');

  parents.forEach((parent) => {
    const eyebrow = parent.querySelector(".eyebrow--w");
    const eyebrowText = eyebrow?.querySelector(".h-eyebrow");
    const title = parent.querySelectorAll('[hl-anim="title"] .word');
    const para = parent.querySelectorAll('[hl-anim="para"] .word');
    const buttonWrap = parent.querySelector('[hl-anim="buttons"]');
    const extraItems = parent.querySelectorAll('[hl-anim="extra-item"]');

    const radiusMed = "0.75em";
    const duration = 0.6;
    const ease1 = "cubic-bezier(0.38,0.005,0.215,1)";
    const ease2 = "power2.inOut";

    // Helper function to safely animate elements
    const safeAnimate = (element, animation) => {
      if (element) {
        if (element.length > 0) {
          gsap.to(element, animation);
        } else if (element instanceof Element) {
          gsap.to(element, animation);
        }
      }
    };

    // Initial states
    safeAnimate(eyebrow, {
      clipPath: `inset(0% calc(100% - ${radiusMed} * 2) 0% 0% round ${radiusMed})`,
      autoAlpha: 0,
      y: "2.5rem",
    });
    safeAnimate(eyebrowText, {
      x: "2.5rem",
      autoAlpha: 0,
    });
    safeAnimate(title, {
      autoAlpha: 0,
      y: "101%",
    });
    safeAnimate(para, {
      autoAlpha: 0,
      y: "101%",
    });
    safeAnimate(buttonWrap, {
      autoAlpha: 0,
      y: "101%",
    });
    safeAnimate(extraItems, {
      autoAlpha: 0,
      y: "5rem",
    });

    // ScrollTrigger for each parent
    ScrollTrigger.create({
      trigger: parent,
      start: "top 80%",
      once: true,
      onEnter: () => {
        // Animations
        safeAnimate(eyebrow, {
          clipPath: `inset(0% 0% 0% 0% round ${radiusMed})`,
          autoAlpha: 1,
          y: 0,
          duration: duration,
          ease: ease1,
        });
        safeAnimate(eyebrowText, {
          x: 0,
          autoAlpha: 1,
          duration: duration,
          ease: ease1,
          delay: 0.3,
        });
        safeAnimate(title, {
          y: "0%",
          autoAlpha: 1,
          duration: duration,
          stagger: 0.05,
          ease: ease2,
        });
        safeAnimate(para, {
          delay: 0.2,
          y: "0%",
          autoAlpha: 1,
          duration: duration,
          stagger: 0.01,
          ease: ease2,
        });
        safeAnimate(buttonWrap, {
          delay: 0.25,
          y: "0%",
          autoAlpha: 1,
          duration: duration,
          ease: ease2,
        });

        // Animate extra items with custom delay
        extraItems.forEach((item, index) => {
          const customDelay = item.getAttribute("hl-anim-delay");
          const delay = customDelay === "0" ? 0 : 0.25 + index * 0.1;

          gsap.to(item, {
            y: "0%",
            autoAlpha: 1,
            duration: duration,
            delay: delay,
            ease: ease2,
          });
        });
      },
    });
  });
}

export function destroyHlAnims() {
  // Kill all ScrollTrigger instances created for headline group animations
  ScrollTrigger.getAll().forEach((trigger) => {
    if (trigger.vars.trigger) {
      const triggerElement =
        trigger.vars.trigger instanceof Element
          ? trigger.vars.trigger
          : typeof trigger.vars.trigger === "string"
          ? document.querySelector(trigger.vars.trigger)
          : null;

      if (triggerElement && triggerElement.hasAttribute("hl-anim")) {
        trigger.kill();
      }
    }
  });

  // Select all elements that were animated
  const parents = document.querySelectorAll('[hl-anim="parent"]');

  parents.forEach((parent) => {
    const eyebrow = parent.querySelector(".eyebrow--w");
    const eyebrowText = eyebrow?.querySelector(".h-eyebrow");
    const title = parent.querySelectorAll('[hl-anim="title"] .word');
    const para = parent.querySelectorAll('[hl-anim="para"] .word');
    const buttonWrap = parent.querySelector('[hl-anim="buttons"]');
    const extraItem = parent.querySelector('[hl-anim="extra-item"]');

    // Helper function to safely clear animations
    const safeClearAnimation = (element) => {
      if (element) {
        if (element.length > 0) {
          gsap.set(element, { clearProps: "all" });
        } else if (element instanceof Element) {
          gsap.set(element, { clearProps: "all" });
        }
      }
    };

    // Clear all GSAP animations and revert elements to their original state
    safeClearAnimation(eyebrow);
    safeClearAnimation(eyebrowText);
    safeClearAnimation(title);
    safeClearAnimation(para);
    safeClearAnimation(buttonWrap);
    safeClearAnimation(extraItem);
  });
}
