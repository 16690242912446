import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// ******
// ---------------------------------------- SECTION IN
// ******

export function impactSectionIn() {
  const section = document.querySelector('[data-s-group="2"]');
  const chars = document.querySelectorAll('[data-s-hl="2"] .char');

  if (!section) {
    console.error("Section not found");
    return;
  }

  // Set initial states
  gsap.set(chars, { autoAlpha: 0, y: "101%" });
  gsap.set(section, { scale: 0.95 });

  // Create the ScrollTrigger for the section
  ScrollTrigger.create({
    // scroller: window.scrollWrap,
    trigger: section,
    start: "top bottom",
    end: "top 50%",
    scrub: true,
    once: true,
    animation: gsap.to(section, {
      scale: 1,
      y: "0%",
      ease: "none", // Linear ease for smooth scrubbing
    }),
  });
  ScrollTrigger.create({
    // scroller: window.scroll  Wrap,
    trigger: section,
    start: "top 80%",
    once: true,
    // markers: true,
    onEnter: () => {
      // Animate chars (this remains the same, not scrubbed)
      gsap.to(chars, {
        delay: 0.5,
        autoAlpha: 1,
        y: "0%",
        duration: 0.6,
        stagger: 0.05,
        ease: "power1.out",
      });
    },
  });
}

export function destroyImpact() {
  const section = document.querySelector('[data-s-group="2"]');
  const chars = document.querySelectorAll('[data-s-hl="2"] .char');

  // Kill all ScrollTriggers associated with this section
  ScrollTrigger.getAll().forEach((trigger) => {
    if (trigger.vars.trigger === section) {
      trigger.kill();
    }
  });

  // Kill all GSAP animations on the section and chars
  gsap.killTweensOf(section);
  gsap.killTweensOf(chars);

  // Reset styles
  if (section) {
    gsap.set(section, { clearProps: "all" });
  }

  if (chars.length) {
    gsap.set(chars, { clearProps: "all" });
  }
}
