import { Core } from "@unseenco/taxi";
import { Renderer } from "@unseenco/taxi";
import { Transition } from "@unseenco/taxi";
import { app } from "./src/app.js";
import "./src/modules/scroll.js";
import {
  onStart,
  transInPersist,
  transOutPersist,
  transitionDuration,
} from "./src/modules/transitions.js";

app();

// console.log("fix.studio");

class MyTransition extends Transition {
  /**
   * Handle the transition leaving the previous page.
   * @param { { from: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
   */
  onLeave({ from, trigger, done }) {
    transOutPersist(from.dataset.page);

    setTimeout(() => {
      done();
    }, transitionDuration);
  }

  /**
   * Handle the transition entering the next page.
   * @param { { to: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
   */
  onEnter({ to, trigger, done }) {
    transInPersist(to.dataset.page);

    done();
  }
}

class CustomRenderer extends Renderer {
  initialLoad() {
    // run code that should only happen once for your site
    // onStart();
    window.addEventListener("DOMContentLoaded", () => {
      onStart();
    });
  }

  onLeave() {
    // console.log("onLeave");
    // run before the transition.onLeave method is called
  }

  onLeaveCompleted() {
    // console.log("onLeaveCompleted");
    // run after the transition.onleave has fully completed
  }

  onEnter() {
    // run after the new content has been added to the Taxi container
  }

  onEnterCompleted() {
    // console.log("onEnterCompleted");
    // run after the transition.onEnter has fully completed
  }
}

const taxi = new Core({
  renderers: {
    default: CustomRenderer,
  },
  transitions: {
    default: MyTransition,
  },
});
