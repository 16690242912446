import rive from "@rive-app/canvas-lite";
import { EventType, RiveEventType } from "@rive-app/canvas-lite";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { heroAnimateIn } from "../pages/home/hero";
import { subHeroAnimateIn } from "../pages/blog/hero";
import { aboutSubHeroIn } from "../pages/about/hero";
import { blogArticleHeroAnimate } from "../pages/blog-article/hero";

gsap.registerPlugin(ScrollTrigger);

let riveMain = [];
let riveSub = [];
window.riveCache = {};
window.riveSubPromise = null;

let layoutCover = new rive.Layout({
  fit: rive.Fit.Cover,
  alignment: rive.Alignment.CenterCenter,
});
let layoutContent = new rive.Layout({
  fit: rive.Fit.Contain,
  alignment: rive.Alignment.CenterCenter,
});

function sizeHandler() {
  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;
}

window.riveAssetsLoaded = false;

function fetchAndCacheRiveFile(url, name) {
  return fetch(url)
    .then((response) => response.arrayBuffer())
    .then((buffer) => {
      riveCache[name] = buffer;
      return buffer;
    });
}

function initializeRiveCache() {
  const preloaderPromise = fetchAndCacheRiveFile(
    "https://assets.itsoffbrand.io/uplink/rive/preloader_events.riv",
    "preloader"
  );

  const uplinkPromise = fetchAndCacheRiveFile(
    "https://assets.itsoffbrand.io/uplink/rive/uplink04.riv",
    "uplink"
  );

  return Promise.all([preloaderPromise, uplinkPromise])
    .then(() => {
      window.riveAssetsLoaded = true;
      // console.log("riveAssetsLoaded: ", window.riveAssetsLoaded);
      return window.riveAssetsLoaded;
    })
    .catch((error) => {
      console.error("Failed to load Rive assets:", error);
      window.riveAssetsLoaded = false;
      return false;
    });
}

export function ensureRiveCache() {
  if (!window.riveAssetsLoaded) {
    return initializeRiveCache();
  }
  return Promise.resolve(window.riveAssetsLoaded);
}

const transWrap = document.querySelector("[data-transition-c]");
const transBG = document.querySelector(".transition--bg");

let siteLoaded;
let transHide;
let transReveal;

export function preloader() {
  return ensureRiveCache().then(() => {
    return new Promise((resolve) => {
      const riveBuffer = riveCache["preloader"];
      if (!riveBuffer) {
        console.error("No cached Rive file found for 'preloader'");
        return;
      }
      const r = new rive.Rive({
        buffer: riveBuffer,
        canvas: document.querySelector("[data-transition-c] canvas"),
        artboard: "preloader",
        stateMachines: "preloader",
        layout: layoutCover,
        autoplay: true,
        onLoad: (_) => {
          r.resizeDrawingSurfaceToCanvas();
          const riveInputs = r.stateMachineInputs("preloader");
          siteLoaded = riveInputs.find((i) => i.name === "site-loaded");
          transHide = riveInputs.find((i) => i.name === "hide");
          transReveal = riveInputs.find((i) => i.name === "reveal");
          resolve();
        },
      });

      r.on(rive.EventType.RiveEvent, onRiveEventReceived);
      const initPage = document.querySelector("[data-page]").dataset.page;

      function onRiveEventReceived(riveEvent) {
        const eventData = riveEvent.data;
        const eventName = eventData.name;

        if (eventData.name === "start-site-loaded") {
          // console.log("start-site-loaded");
          gsap.set(transBG, {
            display: "none",
          });

          if (initPage === "home") {
            setTimeout(heroAnimateIn, 1500);
          } else if (initPage === "blog") {
            setTimeout(subHeroAnimateIn, 1500);
          } else if (initPage === "blog-article") {
            setTimeout(blogArticleHeroAnimate, 1500);
          } else if (initPage === "about") {
            setTimeout(aboutSubHeroIn, 1500);
          }
        } else if (eventData.name === "end-site-loaded") {
          // console.log("end-site-loaded");
          document.body.style.cursor = "auto";
          gsap.set(transWrap, {
            display: "none",
          });
        }

        riveMain.push(r);
      }
    });
  });
}

export function transitionReveal() {
  setTimeout(() => {
    document.body.style.cursor = "auto";
  }, 500);
  if (transReveal) {
    transReveal.fire();
  }
  gsap.set(transWrap, {
    delay: 0.6,
    display: "none",
  });
}

export function transitionHide() {
  document.body.style.cursor = "progress";
  gsap.set(transWrap, {
    display: "flex",
  });
  if (transHide) {
    transHide.fire();
  }
}

export function transitionSiteLoaded() {
  function checkConditions() {
    if (window.riveAssetsLoaded && siteLoaded) {
      siteLoaded.value = true;
      // console.log("siteLoaded: ", siteLoaded);
    } else {
      setTimeout(checkConditions, 100);
    }
  }
  checkConditions();
}

let scrollProgress = 0;

export function community() {
  return ensureRiveCache().then(() => {
    const riveBuffer = riveCache["uplink"];
    if (!riveBuffer) {
      console.error("No cached Rive file found for 'uplink' community");
      return;
    }

    const r = new rive.Rive({
      buffer: riveBuffer,
      canvas: document.querySelector("[rive-community='wrap'] canvas"),
      artboard: "hexagon-scroll",
      stateMachines: "hexagon-scroll",
      layout: layoutContent,
      autoplay: true,
      onLoad: (_) => {
        r.resizeDrawingSurfaceToCanvas();
        const riveInputs = r.stateMachineInputs("hexagon-scroll");
        if (!riveInputs) {
          console.error("State machine inputs are undefined.");
          return;
        }

        const progress = riveInputs.find((i) => i.name === "progress");
        if (!progress) {
          console.error("progress input not found in Rive state machine.");
          return;
        }

        function updateRiveAnimation(newValue) {
          const mappedValue = newValue * 10;
          progress.value = mappedValue;
        }

        riveSub.push({ instance: r, updateAnimation: updateRiveAnimation });

        function riveUpdate() {
          updateRiveAnimation(scrollProgress);
          requestAnimationFrame(riveUpdate);
        }
        requestAnimationFrame(riveUpdate);
      },
    });
  });
}

export function communityProgress() {
  sizeHandler();

  ScrollTrigger.create({
    // scroller: window.scrollWrap,
    trigger: '[rive-community="track"]',
    start: "4% top",
    end: "bottom-=15% bottom",
    // markers: true,
    scrub: 1,
    onUpdate: (self) => {
      const easeProgress = (progress) => {
        return Math.sin((progress * Math.PI) / 2);
      };

      const easedProgress = easeProgress(self.progress);
      scrollProgress = easedProgress * 10;

      if (riveSub.length > 0 && riveSub[0].updateAnimation) {
        riveSub[0].updateAnimation(scrollProgress);
      }
    },
  });

  function riveUpdate() {
    if (riveSub.length > 0 && riveSub[0].updateAnimation) {
      riveSub[0].updateAnimation(scrollProgress);
    }
    window.riveUpdateAnimationFrame = requestAnimationFrame(riveUpdate);
  }
  window.riveUpdateAnimationFrame = requestAnimationFrame(riveUpdate);
}

export function ecosystem() {
  return ensureRiveCache().then(() => {
    return new Promise((resolve) => {
      const riveBuffer = riveCache["uplink"];
      if (!riveBuffer) {
        console.error("No cached Rive file found for 'uplink' ecosystem");
        return;
      }
      let riveInstance;
      const r = new rive.Rive({
        buffer: riveBuffer,
        canvas: document.querySelector('[data-eco="wrap"] canvas'),
        artboard: "ecosystem",
        stateMachines: "ecosystem",
        layout: layoutContent,
        autoplay: false,
        onLoad: (_) => {
          r.resizeDrawingSurfaceToCanvas();

          const riveInputs = r.stateMachineInputs("ecosystem");
          const isMobile = riveInputs.find((i) => i.name === "mobile");
          const ecoPause = riveInputs.find((i) => i.name === "pause");
          const ecoActive = riveInputs.find((i) => i.name === "active");

          if (window.isTabletOrBelow) {
            isMobile.value = true;
          } else {
            isMobile.value = false;
          }

          const tabs = document.querySelectorAll("[eco-tab-id]");
          tabs.forEach((tab, index) => {
            tab.addEventListener("click", () => {
              ecoPause.value = true;
              ecoActive.value = `${index + 1}`;
            });
          });

          riveInstance = r;
          resolve({ riveInstance });
        },
      });

      r.on(rive.EventType.RiveEvent, onRiveEventReceived);

      function onRiveEventReceived(riveEvent) {
        const eventData = riveEvent.data;
        const eventName = eventData.name;

        if (eventName.startsWith("click_") || eventName.startsWith("active_")) {
          const tabNumber = parseInt(eventName.split("_")[1], 10);
          const tabIndex = tabNumber - 1;

          ecoTabs.activateTabByIndex(tabIndex);
        }

        if (eventName === "hover-pointer_off") {
          document.body.style.cursor = "auto";
        } else if (eventName === "hover-pointer_on") {
          document.body.style.cursor = "pointer";
        }
      }

      ScrollTrigger.create({
        // scroller: window.scrollWrap,
        trigger: '[data-eco="wrap"]',
        start: "top 70%",
        onToggle: (self) => {
          if (self.isActive) {
            r.play();
          } else {
            r.pause();
          }
        },
      });

      riveSub.push(r);
    });
  });
}

export function initRiveMain() {
  // console.log("riveMain init");
  return ensureRiveCache()
    .then(() => preloader())
    .then(() => destroyRiveMain);
}

export function initRiveSub() {
  return ensureRiveCache()
    .then(() => riveSubPromise)
    .then(() => community())
    .then(() => {
      communityProgress();
      return ecosystem();
    })
    .then((ecosystemCleanup) => {
      return () => {
        destroyRiveSub();
        if (ecosystemCleanup) ecosystemCleanup();
      };
    });
}

export function destroyRiveSub() {
  ScrollTrigger.getAll().forEach((trigger) => {
    if (trigger.vars.trigger) {
      const triggerElement =
        trigger.vars.trigger instanceof Element
          ? trigger.vars.trigger
          : typeof trigger.vars.trigger === "string"
          ? document.querySelector(trigger.vars.trigger)
          : null;

      if (
        triggerElement &&
        (triggerElement.getAttribute("rive-community") === "track" ||
          triggerElement.getAttribute("data-eco") === "wrap")
      ) {
        trigger.kill();
      }
    }
  });

  riveSub.forEach((instance) => {
    if (instance.cleanup) {
      instance.cleanup();
    } else if (instance.instance && instance.instance.cleanup) {
      instance.instance.cleanup();
    }
  });
  riveSub = [];

  const tabs = document.querySelectorAll("[eco-tab-id]");
  tabs.forEach((tab) => {
    tab.replaceWith(tab.cloneNode(true));
  });
}

export function destroyRiveMain() {
  riveMain.forEach((instance) => {
    if (instance.cleanup) {
      instance.cleanup();
    }
  });
  riveMain = [];

  siteLoaded = undefined;
  transHide = undefined;
  transReveal = undefined;

  const transWrap = document.querySelector("[data-transition-c]");
  const transBG = document.querySelector(".transition--bg");
  gsap.set(transWrap, { clearProps: "all" });
  gsap.set(transBG, { clearProps: "all" });
}
