// Select elements

let currentCycle = 0;

const hexPathOffsets = [1000, 968, 933, 898];
const cycleDuration = 5000; // 5 seconds
const updateInterval = 50; // Update every 50ms for smooth animation

let animationInterval;

function cycleWrap() {
  const parent = document.querySelector('[text-cycle="wrap"]');
  const hex = parent?.querySelector('[text-cycle="hex"]');
  const hexPath = parent?.querySelector("[text-cycle-path]");
  const texts = parent?.querySelectorAll('[text-cycle="text"]');
  const totalCycles = texts?.length; // Should be 3

  function initializeCycle() {
    texts.forEach((text, index) => {
      text.style.transform = `translateY(${index * 100}%)`;
    });
    updateHexPosition();
    hexPath.style.strokeDashoffset = hexPathOffsets[0];
    startAnimation();
  }

  function nextCycle() {
    currentCycle = (currentCycle + 1) % totalCycles;

    texts.forEach((text, index) => {
      const targetPosition =
        ((index - currentCycle + totalCycles) % totalCycles) * 100;
      text.style.transform = `translateY(${targetPosition}%)`;
      text.style.transition = "transform 0.5s ease-in-out";
    });

    updateHexPosition();

    // Call world.cyclePictures with the current cycle
    if (
      typeof world !== "undefined" &&
      typeof world.cyclePictures === "function"
    ) {
      world.cyclePictures(currentCycle);
    }
  }

  function updateHexPosition() {
    const activeText = texts[currentCycle];
    const textWidth = activeText.offsetWidth;
    hex.style.transform = `translateX(${textWidth}px)`;
    hex.style.transition = "transform 0.5s ease-in-out";
  }

  function animateSequence() {
    let sequenceIndex = 0;
    let startTime = Date.now();

    function updateAnimation() {
      const elapsedTime = Date.now() - startTime;
      const cycleProgress = (elapsedTime % cycleDuration) / cycleDuration;

      if (elapsedTime >= cycleDuration) {
        sequenceIndex++;
        if (sequenceIndex >= totalCycles) {
          // Reset the sequence after 3 cycles
          sequenceIndex = 0;
          hexPath.style.strokeDashoffset = hexPathOffsets[0];
        }
        startTime = Date.now();
        nextCycle();
      }

      const startOffset = hexPathOffsets[sequenceIndex];
      const endOffset = hexPathOffsets[sequenceIndex + 1] || hexPathOffsets[0];
      const currentOffset =
        startOffset + (endOffset - startOffset) * cycleProgress;
      hexPath.style.strokeDashoffset = currentOffset;
    }

    animationInterval = setInterval(updateAnimation, updateInterval);
  }

  function startAnimation() {
    animateSequence();
  }

  function stopAnimation() {
    if (animationInterval) {
      clearInterval(animationInterval);
    }
  }

  initializeCycle();
}

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

// Modify initCycle to return the destroy function
export function initCycle() {
  //   console.log("initCycle");
  //   initializeCycle();
  cycleWrap();
  return destroyCycle;
}

export function destroyCycle() {
  const parent = document.querySelector('[text-cycle="wrap"]');
  const hex = parent?.querySelector('[text-cycle="hex"]');
  const hexPath = parent?.querySelector("[text-cycle-path]");
  const texts = parent?.querySelectorAll('[text-cycle="text"]');
  const totalCycles = texts?.length; // Should be 3
  // Reset the current cycle
  currentCycle = 0;

  // Reset text positions
  texts.forEach((text, index) => {
    text.style.transform = `translateY(${index * 100}%)`;
    text.style.transition = "none"; // Remove transition to avoid animation when resetting
  });

  // Reset hex position
  hex.style.transform = "translateX(0)";
  hex.style.transition = "none";

  // Reset hexPath stroke dash offset
  hexPath.style.strokeDashoffset = hexPathOffsets[0];

  // Clear the animation interval if it exists
  if (animationInterval) {
    clearInterval(animationInterval);
    animationInterval = null;
  }
}
