import { gsap } from "gsap";
import { initCycle } from "./heroText";

const radiusMed = "0.75em"; // Changed from CSS variable to fixed value
const duration = 0.6; // Changed to 0.6 seconds
const ease1 = "cubic-bezier(0.38,0.005,0.215,1)";
const ease2 = "power2.inOut";

function heroStates() {
  // Global variables
  const parent = document.querySelector('[dh-anim="parent"]');
  const eyebrow = parent?.querySelector('[dh-anim="eyebrow"]');
  const eyebrowText = eyebrow?.querySelector('[dh-anim="eyebrowText"]');
  const wordsHl = document?.querySelectorAll("[dh-anim='hl'] .word");
  const cycleWrap = parent?.querySelector('[dh-anim="cycle"]');
  const wordsPara = parent?.querySelectorAll('[dh-anim="para"] .word');
  const buttonWrap = parent?.querySelector('[dh-anim="buttons"]');
  const extras = parent?.querySelectorAll('[dh-anim="extras"]');
  // Initial states
  gsap.set(eyebrow, {
    clipPath: `inset(0% calc(100% - ${radiusMed} * 2) 0% 0% round ${radiusMed})`,
    autoAlpha: 0,
    y: "2.5rem",
  });
  gsap.set(eyebrowText, {
    x: "2.5rem",
    autoAlpha: 0,
  });
  gsap.set("[dh-anim='hl'] .word", {
    autoAlpha: 0,
    y: "101%",
  });
  gsap.set('[dh-anim="para"] .word', {
    autoAlpha: 0,
    y: "101%",
  });
  gsap.set(cycleWrap, {
    autoAlpha: 0,
    y: "101%",
  });
  gsap.set(buttonWrap, {
    autoAlpha: 0,
    y: "101%",
  });
  gsap.set(extras, {
    autoAlpha: 0,
    y: "101%",
  });
}

export function heroAnimateIn() {
  // Global variables
  const parent = document.querySelector('[dh-anim="parent"]');
  const eyebrow = parent?.querySelector('[dh-anim="eyebrow"]');
  const eyebrowText = eyebrow?.querySelector('[dh-anim="eyebrowText"]');
  const wordsHl = document?.querySelectorAll("[dh-anim='hl'] .word");
  const cycleWrap = parent?.querySelector('[dh-anim="cycle"]');
  const wordsPara = parent?.querySelectorAll('[dh-anim="para"] .word');
  const buttonWrap = parent?.querySelector('[dh-anim="buttons"]');
  const extras = parent?.querySelectorAll('[dh-anim="extras"]');
  // Animations
  gsap.to(eyebrow, {
    clipPath: `inset(0% 0% 0% 0% round ${radiusMed})`, // Fixed to use radiusMed
    autoAlpha: 1,
    y: 0,
    duration: duration,
    ease: ease1,
  });

  gsap.to(eyebrowText, {
    x: 0,
    autoAlpha: 1,
    duration: duration,
    ease: ease1,
    delay: 0.3,
  });

  gsap.to("[dh-anim='hl'] .word", {
    y: "0%",
    autoAlpha: 1,
    duration: duration,
    stagger: 0.05,
    ease: ease2,
  });

  gsap.to('[dh-anim="para"] .word', {
    delay: 0.2,
    y: "0%",
    autoAlpha: 1,
    duration: duration,
    stagger: 0.01,
    ease: ease2,
  });

  gsap.to(buttonWrap, {
    delay: 0.25,
    y: "0%",
    autoAlpha: 1,
    duration: duration,
    ease: ease2,
  });

  gsap.to(extras, {
    delay: 0.3,
    y: "0%",
    autoAlpha: 1,
    stagger: 0.05,
    duration: duration,
    ease: ease2,
  });

  gsap.to(cycleWrap, {
    delay: 0.15,
    y: "0%",
    autoAlpha: 1,
    duration: duration,
    ease: ease2,
  });
  if (!window.isMob) {
    window.revealNewsItems();
  }
  initCycle();
  // console.log("animate in");
}

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initHero() {
  heroStates();
  return destroyHero;
}

export function destroyHero() {
  // Global variables
  const parent = document.querySelector('[dh-anim="parent"]');
  const eyebrow = parent?.querySelector('[dh-anim="eyebrow"]');
  const eyebrowText = eyebrow?.querySelector('[dh-anim="eyebrowText"]');
  const wordsHl = document?.querySelectorAll("[dh-anim='hl'] .word");
  const cycleWrap = parent?.querySelector('[dh-anim="cycle"]');
  const wordsPara = parent?.querySelectorAll('[dh-anim="para"] .word');
  const buttonWrap = parent?.querySelector('[dh-anim="buttons"]');
  // Kill specific GSAP animations
  const elements = [
    eyebrow,
    eyebrowText,
    "[dh-anim='hl'] .word",
    '[dh-anim="para"] .word',
    cycleWrap,
    buttonWrap,
  ];

  elements.forEach((element) => {
    gsap.killTweensOf(element);
  });

  // Reset elements to their initial state
  gsap.set(eyebrow, {
    clearProps: "all",
  });
  gsap.set(eyebrowText, {
    clearProps: "all",
  });
  gsap.set("[dh-anim='hl'] .word", {
    clearProps: "all",
  });
  gsap.set('[dh-anim="para"] .word', {
    clearProps: "all",
  });
  gsap.set(cycleWrap, {
    clearProps: "all",
  });
  gsap.set(buttonWrap, {
    clearProps: "all",
  });

  // Destroy the cycle animation
  // if (typeof destroyCycle === "function") {
  //   destroyCycle();
  // }
}
