import { initSubHero, aboutSubHeroIn, destroySubHero } from "./hero";
import { initHlAnims, destroyHlAnims } from "../../modules/animations";
import { partnerCardsIn, destroyPartnerCards } from "../home/partners";

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initEnterprise(transition = false) {
  initHlAnims();
  partnerCardsIn();
  if (transition) {
    // transitioning to home
    // initSubHero();
    // setTimeout(aboutSubHeroIn, 50);
  } else {
    // no transition
    // initSubHero();
  }
  if (!window.isTabletOrBelow) {
  }
}

export function destroyEnterprise() {
  // destroySubHero();
  destroyHlAnims();
  // destroyTeamCards();
  destroyPartnerCards();
}
