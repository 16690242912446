import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let typeSplit;

// ******
// -------------------------------- COMMUNITY
// ******

// Function to initialize the state of all `.word` elements
function initComStates() {
  gsap.set(".com__title--w .word", { autoAlpha: 0.35, y: "101%" });
}

function initComIntro() {
  const parent = document.querySelector("[data-com='parent']");
  const intro = parent.querySelector("[data-com='intro']");
  const outro = parent.querySelector("[data-com='outro']");
  const eyebrow = parent.querySelector("[data-com='eyebrow']");
  const hex = parent.querySelector("[data-com='hex']");
  const buttonWrap = parent.querySelector("[data-com='buttons']");

  gsap.set([eyebrow, hex, buttonWrap], { autoAlpha: 0, y: "101%" });

  //Intro ScrollTrigger
  gsap.from([eyebrow, hex, buttonWrap], {
    scrollTrigger: {
      // scroller: window.scrollWrap,
      trigger: intro,
      start: "bottom 80%",
      //   markers: true,
      onEnter: () =>
        gsap.to([eyebrow, hex, buttonWrap], { autoAlpha: 1, y: "0%" }),
      onLeaveBack: () =>
        gsap.to([eyebrow, hex, buttonWrap], { autoAlpha: 0, y: "101%" }),
    },
  });

  // Outro ScrollTrigger
  gsap.from([eyebrow, hex, buttonWrap], {
    scrollTrigger: {
      // scroller: window.scrollWrap,
      trigger: outro,
      start: "bottom 80%",
      end: "bottom top",
      scrub: true,
      // markers: true,
      onEnter: () =>
        gsap.to([eyebrow, hex, buttonWrap], { autoAlpha: 0, y: "-101%" }),
      onLeaveBack: () =>
        gsap.to([eyebrow, hex, buttonWrap], { autoAlpha: 1, y: "0%" }),
    },
  });
}

// Function to initialize ScrollTriggers for each pair of text and trigger elements
function initComScroll() {
  const textElements = document.querySelectorAll("[data-com-text]");
  const triggerElements = document.querySelectorAll("[data-com-trigger]");

  if (textElements.length !== triggerElements.length) {
    console.error("Text elements and trigger elements count do not match!");
    return;
  }

  textElements.forEach((textElement, index) => {
    const triggerElement = triggerElements[index];
    const words = textElement.querySelectorAll(".word");

    let tl = gsap.timeline({
      scrollTrigger: {
        // scroller: window.scrollWrap,
        trigger: triggerElement,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      },
    });

    // STEP 1: Animate y to 0%
    tl.to(
      words,
      {
        y: "0%",
        duration: 1,
        stagger: 0.1,
        ease: "power1.out",
      },
      0
    );

    // STEP 2: Animate autoAlpha to 1
    tl.to(
      words,
      {
        autoAlpha: 1,
        duration: 1,
        stagger: 0.15,
        ease: "power1.out",
      },
      0.25 * tl.duration()
    );

    // STEP 3: Hold autoAlpha at 1
    tl.to(
      words,
      {
        autoAlpha: 1,
        duration: 1,
        stagger: 0.1,
        ease: "power1.out",
      },
      0.5 * tl.duration()
    );
    tl.to(
      words,
      {
        autoAlpha: 1,
        duration: 1,
        stagger: 0.1,
        ease: "power1.out",
      },
      0.6 * tl.duration()
    );

    // STEP 4: Animate y to -101% and autoAlpha to 0
    tl.to(
      words,
      {
        y: "-101%",
        autoAlpha: 0,
        duration: 1,
        stagger: 0.1,
        ease: "power1.out",
      },
      0.75 * tl.duration()
    );
  });
}

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

// Modify initCommunity to return the destroy function
export function initCommunity() {
  initComStates();
  initComScroll();
  initComIntro();

  return destroyCommunity;
}

export function destroyCommunity() {
  // Kill all ScrollTriggers
  ScrollTrigger.getAll().forEach((trigger) => trigger.kill());

  // Reset all GSAP animations
  // gsap.killTweensOf("*");

  // Reset the state of all `.word` elements
  gsap.set(".com__title--w .word", { clearProps: "all" });

  // Reset the state of intro elements
  const parent = document.querySelector("[data-com='parent']");
  if (parent) {
    const eyebrow = parent.querySelector("[data-com='eyebrow']");
    const hex = parent.querySelector("[data-com='hex']");
    const buttonWrap = parent.querySelector("[data-com='buttons']");
    gsap.set([eyebrow, hex, buttonWrap], { clearProps: "all" });
  }

  // Remove any event listeners (if any were added)
  const textElements = document.querySelectorAll("[data-com-text]");
  const triggerElements = document.querySelectorAll("[data-com-trigger]");

  textElements.forEach((el) => {
    el.replaceWith(el.cloneNode(true));
  });

  triggerElements.forEach((el) => {
    el.replaceWith(el.cloneNode(true));
  });

  // Clear any global variables
  typeSplit = undefined;
}
