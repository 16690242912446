import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// ******
// -------------------------------- PRODUCT SECTION
// ******
function initProductScroll() {
  // Increment value in degrees
  const increment = 10;

  // Select the paths
  const paths = document.querySelectorAll(
    '[data-prod="s"] [data-prod="svg"] path'
  );

  // Iterate through each path and set up the scroll trigger animation
  paths.forEach((path, index) => {
    const rotationAngle = (index + 1) * increment;
    gsap.to(path, {
      scrollTrigger: {
        // scroller: window.scrollWrap,
        trigger: '[data-prod="s"]',
        start: "top center",
        end: "bottom top",
        scrub: true,
      },
      rotation: rotationAngle,
      transformOrigin: "center center",
      ease: "none",
    });
  });
}

function initProductLoad() {
  // Select the paths
  const paths = Array.from(
    document.querySelectorAll('[data-prod="s"] [data-prod="svg"] path')
  );

  // Set initial state
  gsap.set(paths, { autoAlpha: 0, scale: 0.9 });

  // Create the ScrollTrigger for when the element enters the viewport
  ScrollTrigger.create({
    // scroller: window.scrollWrap,
    trigger: '[data-prod="s"]',
    start: "top 90%",
    onEnter: () => {
      gsap.to(paths, {
        // Animate in the forward order
        autoAlpha: 1,
        scale: 1,
        stagger: 0.1,
        ease: "power1.inOut",
        duration: 0.5,
      });
    },
  });
}

function initProdUI() {
  const uiElems = document.querySelectorAll('[data-prod="ui"]');

  // Set initial state: clip from bottom
  gsap.set(uiElems, { y: "5rem", autoAlpha: 0 });

  // Create ScrollTrigger for reveal animation
  ScrollTrigger.create({
    trigger: '[data-prod="s"]',
    start: "top center",
    onEnter: () => {
      gsap.to(uiElems, {
        autoAlpha: 1,
        // clipPath: "inset(0% 0 0 0)",
        y: "0rem",
        duration: 1,
        ease: "power2.out",
        stagger: 0.2,
      });
    },
  });
}

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export function initProduct() {
  initProductScroll();
  initProductLoad();
  initProdUI();
}

export function destroyProduct() {
  // Select all relevant elements
  const productSection = document.querySelector('[data-prod="s"]');
  const paths = document.querySelectorAll(
    '[data-prod="s"] [data-prod="svg"] path'
  );

  // Kill all ScrollTriggers associated with the product section
  ScrollTrigger.getAll().forEach((trigger) => {
    if (trigger.vars.trigger === productSection) {
      trigger.kill();
    }
  });

  // Kill all GSAP animations on the paths
  if (paths.length) {
    gsap.killTweensOf(paths);
  }

  // Reset styles on all paths
  paths.forEach((path) => {
    gsap.set(path, {
      clearProps: "all",
      rotation: 0,
      transformOrigin: "center center",
      autoAlpha: 1,
      scale: 1,
    });
  });

  // Reset UI elements to their original state
  const uiElems = document.querySelectorAll('[data-prod="ui"]');
  gsap.set(uiElems, { clipPath: "inset(0% 0 0 0)" });
}
