import { initHlAnims, destroyHlAnims } from "../../modules/animations";
import {
  blogArticleHeroAnimate,
  blogArticleHeroStates,
  destroyBlogArticleHero,
} from "./hero";

// ******
// ---------------------------------------- LAUNCH CONTROL
// ******

export async function initBlogArticle(transition = false) {
  initHlAnims();
  if (transition) {
    // transitioning to home
    blogArticleHeroStates();
    setTimeout(blogArticleHeroAnimate, 50);
  } else {
    // no transition
    blogArticleHeroStates();
  }
  if (!window.isTabletOrBelow) {
  }
}

export function destroyBlogArticle() {
  destroyHlAnims();
  destroyBlogArticleHero();
}
