import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function partnerCardsIn() {
  const parent = document.querySelector('[par-anim="parent"]');
  const items = parent.querySelectorAll('[par-anim="item"]');
  const duration = 0.6;
  const ease1 = "cubic-bezier(0.38,0.005,0.215,1)";

  // Set the x and y values for animations
  const xOffset = 5; // rem
  const yOffset = 5; // rem

  // Function to set initial positions
  function setInitialPositions() {
    const itemsArray = Array.from(items);
    const rows = [];
    let currentRow = [];
    let prevBottom = items[0].offsetTop;

    itemsArray.forEach((item, index) => {
      if (item.offsetTop !== prevBottom) {
        rows.push(currentRow);
        currentRow = [];
        prevBottom = item.offsetTop;
      }
      currentRow.push(item);

      if (index === itemsArray.length - 1) {
        rows.push(currentRow);
      }
    });

    rows.forEach((row) => {
      const rowLength = row.length;

      if (rowLength === 4) {
        gsap.set(row[0], {
          x: -xOffset + "rem",
          y: yOffset + "rem",
          autoAlpha: 0,
        });
        gsap.set(row[1], {
          x: -xOffset + "rem",
          y: yOffset + "rem",
          autoAlpha: 0,
        });
        gsap.set(row[2], {
          x: xOffset + "rem",
          y: yOffset + "rem",
          autoAlpha: 0,
        });
        gsap.set(row[3], {
          x: xOffset + "rem",
          y: yOffset + "rem",
          autoAlpha: 0,
        });
      } else if (rowLength === 3) {
        gsap.set(row[0], {
          x: -xOffset + "rem",
          y: yOffset + "rem",
          autoAlpha: 0,
        });
        gsap.set(row[1], { x: 0, y: yOffset + "rem", autoAlpha: 0 });
        gsap.set(row[2], {
          x: xOffset + "rem",
          y: yOffset + "rem",
          autoAlpha: 0,
        });
      } else {
        // For any other number of items in a row, just set them to come from below
        row.forEach((item) => {
          gsap.set(item, { y: yOffset + "rem", autoAlpha: 0 });
        });
      }
    });
  }

  // Set initial positions
  setInitialPositions();

  // Create the ScrollTrigger animation
  ScrollTrigger.create({
    // scroller: window.scrollWrap,
    trigger: parent,
    start: "top 80%",
    once: true,
    onEnter: () => {
      gsap.to(items, {
        x: 0,
        y: 0,
        autoAlpha: 1,
        duration: duration,
        ease: ease1,
        stagger: 0.05,
      });
    },
  });
}

export function destroyPartnerCards() {
  // Get all elements
  const parent = document.querySelector('[par-anim="parent"]');
  const items = parent ? parent.querySelectorAll('[par-anim="item"]') : [];

  // Kill the ScrollTrigger
  ScrollTrigger.getAll().forEach((trigger) => {
    if (trigger.vars.trigger === parent) {
      trigger.kill();
    }
  });

  // Kill any ongoing GSAP animations on the items
  if (items.length) {
    gsap.killTweensOf(items);
  }

  // Reset styles on all items
  items.forEach((item) => {
    gsap.set(item, { clearProps: "all" });
  });
}
