import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ease1 = "cubic-bezier(0.38,0.005,0.215,1)";
const duration = "0.6";
const radiusMed = "0.75em";

export function blogArticleHeroStates() {
  const parent = document.querySelector('[data-featured="parent"]');
  const mask = parent?.querySelector('[data-featured="mask"]');
  const words = parent?.querySelectorAll('[data-featured="text"] .word');
  const para = parent?.querySelectorAll('[data-featured="para"] .word');
  const tag = parent?.querySelector('[data-featured="tag"]');
  const button = parent?.querySelector('[data-featured="button"]');

  // Set initial states
  gsap.set(words, { autoAlpha: 0, y: "101%" });
  gsap.set(para, { autoAlpha: 0, y: "101%" });
  gsap.set(tag, { autoAlpha: 0, y: "101%" });
  gsap.set(button, { autoAlpha: 0, y: "101%" });
}

export function blogArticleHeroAnimate() {
  const parent = document.querySelector('[data-featured="parent"]');
  const mask = parent?.querySelector('[data-featured="mask"]');
  const words = parent?.querySelectorAll('[data-featured="text"] .word');
  const para = parent?.querySelectorAll('[data-featured="para"] .word');
  const tag = parent?.querySelector('[data-featured="tag"]');
  const button = parent?.querySelector('[data-featured="button"]');

  gsap.fromTo(
    mask,
    {
      clipPath: "inset(100% 25% 0% 25% round 0px)",
    },
    {
      clipPath: "inset(0% 0% 0% 0% round 30px)",
      duration: 2,
      ease: "power2.inOut",
    },
  );
  gsap.to(words, {
    delay: 1,
    autoAlpha: 1,
    y: "0%",
    duration: 1,
    stagger: 0.1,
    ease: "power2.inOut",
  });
  gsap.to(para, {
    delay: 1.1,
    autoAlpha: 1,
    y: "0%",
    duration: 1,
    stagger: 0.01,
    ease: ease1,
  });
  gsap.to(tag, {
    delay: 1,
    autoAlpha: 1,
    y: "0%",
    duration: 1,
    ease: "power2.inOut",
  });
  gsap.to(button, {
    delay: 1.3,
    autoAlpha: 1,
    y: "0%",
    duration: 1,
    ease: "power2.inOut",
  });
}

export function destroyBlogArticleHero() {
  const parent = document.querySelector('[data-featured="parent"]');
  const mask = parent?.querySelector('[data-featured="mask"]');
  const words = parent?.querySelectorAll('[data-featured="text"] .word');
  const para = parent?.querySelectorAll('[data-featured="para"] .word');
  const tag = parent?.querySelector('[data-featured="tag"]');
  const button = parent?.querySelector('[data-featured="button"]');

  // Kill all GSAP animations
  gsap.killTweensOf([mask, words, para, tag, button]);

  //   // Reset elements to their initial state
  //   gsap.set(mask, { clipPath: "inset(50% 25% 0% 25% round 0px)" });
  //   gsap.set([words, para, tag, button], { clearProps: "all" });

  //   // Re-apply initial states
  //   gsap.set(words, { autoAlpha: 0, y: "101%" });
  //   gsap.set(para, { autoAlpha: 0, y: "101%" });
  //   gsap.set(tag, { autoAlpha: 0, y: "101%" });
  //   gsap.set(button, { autoAlpha: 0, y: "101%" });
}
