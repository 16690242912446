import { gsap } from "gsap";

const radiusMed = "0.75em";
const duration = 0.6;
const ease1 = "cubic-bezier(0.38,0.005,0.215,1)";
const ease2 = "power2.inOut";

function subHeroStates() {
  const parent = document.querySelector('[sub-anim="parent"]');
  const titleWords = parent?.querySelectorAll('[sub-anim="title"] .word');
  const paraWords = parent?.querySelectorAll('[sub-anim="para"] .word');
  const eyebrow = parent?.querySelector(".eyebrow--w");
  const button = parent?.querySelector('[sub-anim="buttons"]');
  const section = document.querySelector('[data-s-group="1"]');
  const chars = document.querySelectorAll('[data-s-hl="1"] .char');

  // Set initial states
  gsap.set(chars, { autoAlpha: 0, y: "101%" });
  gsap.set(section, { y: "40vh" });

  gsap.set(eyebrow, {
    clipPath: `inset(0% calc(100% - ${radiusMed} * 2) 0% 0% round ${radiusMed})`,
    autoAlpha: 0,
    y: "2.5rem",
  });

  gsap.set(titleWords, {
    autoAlpha: 0,
    y: "101%",
  });

  gsap.set(paraWords, {
    autoAlpha: 0,
    y: "101%",
  });

  gsap.set(button, {
    autoAlpha: 0,
    y: "101%",
  });
}

export function subHeroAnimateIn() {
  const parent = document.querySelector('[sub-anim="parent"]');
  const titleWords = parent?.querySelectorAll('[sub-anim="title"] .word');
  const paraWords = parent?.querySelectorAll('[sub-anim="para"] .word');
  const eyebrow = parent?.querySelector(".eyebrow--w");
  const button = parent?.querySelector('[sub-anim="buttons"]');

  gsap.to(eyebrow, {
    clipPath: `inset(0% 0% 0% 0% round ${radiusMed})`,
    autoAlpha: 1,
    y: 0,
    duration: duration,
    ease: ease1,
  });

  gsap.to(titleWords, {
    y: "0%",
    autoAlpha: 1,
    duration: duration,
    stagger: 0.05,
    ease: ease2,
  });

  gsap.to(paraWords, {
    delay: 0.2,
    y: "0%",
    autoAlpha: 1,
    duration: duration,
    stagger: 0.01,
    ease: ease2,
  });

  gsap.to(button, {
    delay: 0.25,
    y: "0%",
    autoAlpha: 1,
    duration: duration,
    ease: ease2,
  });

  blogFeaturedIn();
}

// FEATURED!

function blogFeaturedIn() {
  const section = document.querySelector('[data-s-group="1"]');
  const chars = document.querySelectorAll('[data-s-hl="1"] .char');

  if (!section) {
    console.error("Section not found");
    return;
  }

  // Set initial states
  gsap.set(chars, { autoAlpha: 0, y: "101%" });
  gsap.set(section, { y: "40vh" });

  gsap.to(section, {
    delay: 0.2,
    duration: duration,
    scale: 1,
    y: "0%",
    ease: ease1,
  }),
    gsap.to(chars, {
      delay: 0.3,
      autoAlpha: 1,
      y: "0%",
      duration: 0.6,
      stagger: 0.05,
      ease: ease1,
    });
}

export function initSubHero() {
  subHeroStates();
  return destroySubHero;
}

export function destroySubHero() {
  const parent = document.querySelector('[sub-anim="parent"]');
  const titleWords = parent?.querySelectorAll('[sub-anim="title"] .word');
  const paraWords = parent?.querySelectorAll('[sub-anim="para"] .word');
  const eyebrow = parent?.querySelector(".eyebrow--w");
  const button = parent?.querySelector('[sub-anim="buttons"]');

  // New elements for blogFeaturedIn
  const featuredSection = document.querySelector('[data-s-group="1"]');
  const featuredChars = document.querySelectorAll('[data-s-hl="1"] .char');

  const elements = [
    eyebrow,
    titleWords,
    paraWords,
    button,
    featuredSection,
    featuredChars,
  ];

  // Kill all tweens
  elements.forEach((element) => {
    gsap.killTweensOf(element);
  });

  // Reset all elements to their initial state
  elements.forEach((element) => {
    gsap.set(element, {
      clearProps: "all",
    });
  });

  // Additional reset for featuredSection if needed
  if (featuredSection) {
    gsap.set(featuredSection, {
      y: "0",
      scale: 1,
    });
  }
}
