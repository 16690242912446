import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export function initTeamCards() {
  const cardWrap = document.querySelector("[tc-parent]");
  const cards = cardWrap?.querySelectorAll("[tc-card]");
  const ease1 = "cubic-bezier(0.38,0.005,0.215,1)";
  const duration = 0.6;

  if (!cardWrap || !cards.length) return;

  cards.forEach((card) => {
    const elements = card.querySelectorAll("[tc-anim]");
    const mask = card.querySelector("[mask-hex-basic]");

    if (!elements.length || !mask) return;

    // Set initial states
    // gsap.set(card, { autoAlpha: 0, y: "2.5rem" });
    gsap.set(elements, { autoAlpha: 0, y: "2.5rem" });
    gsap.set(mask, {
      maskSize: "0%",
      webkitMaskSize: "0%",
    });
  });

  // Create the main timeline
  const mainTl = gsap.timeline({
    scrollTrigger: {
      trigger: cardWrap,
      start: "top 80%",
      once: true,
      // markers: true,
    },
  });

  // Animate each card with a stagger
  cards.forEach((card, index) => {
    const elements = card.querySelectorAll("[tc-anim]");
    const mask = card.querySelector("[mask-hex-basic]");

    const cardTl = gsap.timeline();

    // Animate card
    // cardTl.to(card, {
    //   y: "0%",
    //   autoAlpha: 1,
    //   duration: duration,
    //   ease: ease1,
    // });

    // Animate mask
    cardTl.to(
      mask,
      {
        maskSize: "100%",
        webkitMaskSize: "100%",
        duration: 1,
        ease: ease1,
      },
      "<"
    );

    // Animate elements
    cardTl.to(
      elements,
      {
        y: "0%",
        autoAlpha: 1,
        duration: duration,
        ease: ease1,
        stagger: 0.1,
      },
      "<"
    );

    // Add this card's timeline to the main timeline with a stagger
    mainTl.add(cardTl, index * 0.2);
  });
}

export function destroyTeamCards() {
  const cardWrap = document.querySelector("[tc-parent]");
  const cards = cardWrap?.querySelectorAll("[tc-card]");

  if (!cardWrap || !cards.length) return;

  // Kill the ScrollTrigger for the main timeline
  ScrollTrigger.getAll().forEach((st) => {
    if (st.vars.trigger === cardWrap) {
      st.kill();
    }
  });

  // Kill all tweens associated with the cards and their children
  cards.forEach((card) => {
    const elements = card.querySelectorAll("[tc-anim]");
    const mask = card.querySelector("[mask-hex-basic]");

    gsap.killTweensOf([card, elements, mask]);

    // Reset elements to their initial state
    gsap.set(elements, { clearProps: "all" });
    gsap.set(mask, { clearProps: "all" });
  });

  // Clear any inline styles added by GSAP to the card wrapper
  gsap.set(cardWrap, { clearProps: "all" });

  // If you've added any event listeners, remove them here
  // For example:
  // cardWrap.removeEventListener('click', handleClick);

  console.log("Team cards destroyed and reset");
}
