import {
  runWelcome,
  callAfterResize,
  onResize,
  reloadOnResize,
} from "./modules/utils";

import {
  initCookies
} from "./modules/cookies"

export function app() {
  runWelcome();
  callAfterResize(onResize);
  reloadOnResize();
  initCookies();

  if (!window.isTabletOrBelow) {
  }
  if (!window.prm) {
  }

  // combined scroll trigger refresh
  if (!window.prm && !window.isTabletOrBelow) {
    window.addEventListener("load", () => {
      // combinedRefresh();
    });
  }
}
