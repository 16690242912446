import { gsap } from "gsap";

export function initCookies() {
  const cookieBanner = document.querySelector('[data-cookie="parent"]');
  const acceptButton = cookieBanner?.querySelector('[data-cookie="accept"]');
  const rejectButton = cookieBanner?.querySelector('[data-cookie="reject"]');

  const COOKIE_FLAG_KEY = "cookiesAccepted";
  const GA_MEASUREMENT_ID = "G-09WK5TC5DF";

  function getCookieFlag() {
    return localStorage.getItem(COOKIE_FLAG_KEY);
  }

  function setCookieFlag(value) {
    localStorage.setItem(COOKIE_FLAG_KEY, value.toString());
  }

  function animateIn() {
    gsap.to(cookieBanner, {
      y: "0rem",
      autoAlpha: 1,
      duration: 0.6,
      ease: "power2.inOut",
    });
  }

  function animateOut() {
    gsap.to(cookieBanner, {
      y: "5rem",
      autoAlpha: 0,
      duration: 0.6,
      ease: "power2.inOut",
    });
  }

  function handleCookieResponse(accepted) {
    setCookieFlag(accepted);
    animateOut();

    if (accepted) {
      enableGATracking();
    } else {
      disableGATracking();
    }
  }

  function disableGATracking() {
    window[`ga-disable-${GA_MEASUREMENT_ID}`] = true;

    // Clear any existing cookies
    document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "_gat=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  function enableGATracking() {
    window[`ga-disable-${GA_MEASUREMENT_ID}`] = false;
  }

  // Check if it's the first visit or apply previous choice
  const cookieFlag = getCookieFlag();

  if (cookieFlag === null) {
    animateIn();
  } else {
    gsap.set(cookieBanner, { autoAlpha: 0, y: "5rem" });

    if (cookieFlag === "true") {
      enableGATracking();
    } else {
      disableGATracking();
    }
  }

  // Add event listeners to buttons
  acceptButton?.addEventListener("click", () => handleCookieResponse(true));
  rejectButton?.addEventListener("click", () => handleCookieResponse(false));
}
